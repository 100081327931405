import React, { createContext, useContext } from "react";
import useCalendars, { UseCalendars } from "../logic/hooks/useCalendars";

const CalendarContext: React.Context<UseCalendars | {}> = createContext({});
export const useCalendarsContext = (): UseCalendars =>
  useContext(CalendarContext) as UseCalendars;

function CalendarsContextProvider({ children }: { children: React.ReactNode }) {
  const { calendars, updateCalendarVisibility } = useCalendars();

  return (
    <CalendarContext.Provider value={{ calendars, updateCalendarVisibility }}>
      {children}
    </CalendarContext.Provider>
  );
}

export default CalendarsContextProvider;
