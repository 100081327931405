import React from "react";
import { Container, Footer } from "../landing.page.style";

const PageFooterComponent: React.FC = () => {
  return (
    <Footer>
      <Container>
        {/* PageFooter content */}
        <div>Footer Content Goes Here</div>
        {/* Contact information */}
        <div>Contact Link Goes Here</div>
      </Container>
    </Footer>
  );
};

export default PageFooterComponent;
