import { TaskClass } from "../../models/classes/task.class";

export class DATE {
  static readonly ONE_HOUR_MS = 60 * 60 * 1000;
  static readonly DAY_NAMES = [
    "Sun",
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat",
  ] as const;
  // Must be the same as what's in the database
  static readonly REPEAT_WEEK_DAYS = [
    "su",
    "mo",
    "tu",
    "we",
    "th",
    "fr",
    "sa",
  ] as const;
  static readonly MIN_PER_DAY = 24 * 60;
  static readonly LAST_WEEK_DAY = 6;
  static readonly FIRST_DAY = 0;
  static readonly TIME_SLOTS = [
    "12 AM",
    "1 AM",
    "2 AM",
    "3 AM",
    "4 AM",
    "5 AM",
    "6 AM",
    "7 AM",
    "8 AM",
    "9 AM",
    "10 AM",
    "11 AM",
    "12 PM",
    "1 PM",
    "2 PM",
    "3 PM",
    "4 PM",
    "5 PM",
    "6 PM",
    "7 PM",
    "8 PM",
    "9 PM",
    "10 PM",
    "11 PM",
    "12 AM",
  ];
  private date: Date;

  constructor(date: Date) {
    this.date = date;
  }

  static areSameDay = (day1: Date, day2: Date): boolean =>
    day1.toDateString() === day2.toDateString();

  static getValidDate = (dates: Date[]): Date =>
    dates[DATE.LAST_WEEK_DAY] || dates[DATE.FIRST_DAY];

  static toPrettyDateTime = (date: Date): string => {
    return date.toLocaleDateString() + " " + DATE.toTimestamp(date);
  };

  static toTimestamp = (date: Date): string =>
    date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });

  /**
   * Given a start date it returns a start date and end date that's an hour after the start
   * @param start
   * @param useTodayTime - if provided, then the time (NOT date) will be updated to the current time
   */
  static getTaskDates(start: Date, useTodayTime?: boolean) {
    const startDate = new Date(start);
    if (useTodayTime) {
      const today = new Date();
      startDate.setHours(today.getHours(), today.getMinutes(), 0, 0);
    }
    const endDate = DATE.timeFromDate(1, 0, startDate);

    return { startDate, endDate };
  }

  /**
   * Given a date and a list of tasks, it returns the tasks in the list that are happening
   * during the date. Meaning the task start on the date or before, AND, ends on the date
   * or after
   * @param date
   * @param tasks
   */
  static getTasksHappeningDuring(date: Date, tasks: TaskClass[]): TaskClass[] {
    return tasks.filter((task) => {
      const doesOccurDuringDate =
        task.startDate < DATE.getNextDayMidnight(date) && task.endDate > date;
      return doesOccurDuringDate || DATE.doesRepeatOnDate(date, task);
    });
  }

  /**
   * Returns a very far date in the future. It's used for repeating tasks that don't
   * have an end date instead of setting the end date to null. This allows for querying
   * the repeating tasks that end after a given date
   */
  static getMaxPossibleDate() {
    return new Date(86400000000000);
  }

  /**
   * Returns true if the given date is the maximum possible date
   * @param date
   */
  static isMaxPossibleDate(date: Date) {
    return date.getTime() >= DATE.getMaxPossibleDate().getTime();
  }

  static getTotalMinutes = (date: Date): number =>
    date.getMinutes() + date.getHours() * 60;

  static timeFromDate(hours: number, minutes: number = 0, date = new Date()) {
    const timeToAdd = hours * 60 * 60 * 1000 + minutes * 60 * 1000;
    return new Date(date.getTime() + timeToAdd);
  }

  static getDayName(date: Date) {
    switch (date.getDay()) {
      case 0:
        return "Sunday";
      case 1:
        return "Monday";
      case 2:
        return "Tuesday";
      case 3:
        return "Wednesday";
      case 4:
        return "Thursday";
      case 5:
        return "Friday";
      case 6:
        return "Saturday";
      default:
        return "Sunday";
    }
  }

  static getMonthName(date: Date) {
    switch (date.getMonth()) {
      case 0:
        return "January";
      case 1:
        return "February";
      case 2:
        return "March";
      case 3:
        return "April";
      case 4:
        return "May";
      case 5:
        return "June";
      case 6:
        return "July";
      case 7:
        return "August";
      case 8:
        return "September";
      case 9:
        return "October";
      case 10:
        return "November";
      case 11:
        return "December";
      default:
        return "January";
    }
  }

  static getDateInfo = (
    date: Date
  ): { year: number; month: number; day: number } => ({
    year: date.getFullYear(),
    month: date.getMonth(),
    day: date.getDate(),
  });

  static toHtmlInput = (date?: Date | null): string => {
    if (!date) return "";
    return date.toISOString().split("T")[0];
  };

  static toHTML_Datetime(date: Date): string {
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    const hours = ("0" + date.getHours()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);
    const seconds = ("0" + date.getSeconds()).slice(-2);

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  }

  /**
   * Given a date it returns the next day at midnight
   * Jan/14/2022 05:01 PM returns Jan/15/2022 12:00 AM
   */
  static getNextDayMidnight(date: Date): Date {
    const nextDay = new Date(date);
    nextDay.setDate(date.getDate() + 1);
    nextDay.setHours(0, 0, 0, 0);
    return nextDay;
  }

  static getDatesInRange = (startDate: Date, endDate: Date): Date[] => {
    if (endDate < startDate) {
      throw new Error("End date must be greater than start date");
    }
    startDate.setHours(0, 0, 0, 0);
    let dates = [];
    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  };

  static getMonthDates(date: Date): Date[] {
    const { year, month } = DATE.getDateInfo(date);
    const daysInMonth = (month: number, year: number) =>
      new Date(year, month + 1, 0).getDate();
    let days = daysInMonth(month, year);
    const firstDay = new Date(year, month, 1).getDay();
    const lastDay = new Date(year, month, days).getDay();
    let prevMonthDates = [];
    let nextMonthDates = [];
    let calendarDates = [];

    // Get dates from previous month
    if (firstDay !== 0) {
      const prevMonth = month === 0 ? 11 : month - 1;
      const prevMonthYear = month === 0 ? year - 1 : year;
      const prevMonthDaysCount = daysInMonth(prevMonth, prevMonthYear);
      for (let i = firstDay - 1; i >= 0; i--) {
        prevMonthDates.unshift(
          new Date(prevMonthYear, prevMonth, prevMonthDaysCount - i)
        );
      }
      prevMonthDates.reverse();
    }

    // Get dates from current month
    for (let i = 1; i <= days; i++) {
      calendarDates.push(new Date(year, month, i));
    }

    // Get dates from next month
    if (lastDay !== 6) {
      const nextMonth = month === 11 ? 0 : month + 1;
      const nextMonthYear = month === 11 ? year + 1 : year;
      for (let i = 1; i <= 6 - lastDay; i++) {
        nextMonthDates.push(new Date(nextMonthYear, nextMonth, i));
      }
    }

    return [...prevMonthDates, ...calendarDates, ...nextMonthDates];
  }

  static getWeekDates(date: Date): Date[] {
    const { year, month, day } = DATE.getDateInfo(date);
    // create current date using year, month, and day inputs
    const currentDate = new Date(year, month, day);
    let currentDay = currentDate.getDay();
    let weekDates = [];
    // find the closest Sunday to the current date
    let newDate = new Date(year, month, day);
    for (let i = -currentDay; i < 7 - currentDay; i++) {
      newDate.setDate(currentDate.getDate() + i);
      if (newDate.getDay() === 0) {
        break;
      }
    }
    // create a new loop which starts from the closest sunday,
    // and goes for 7 days
    for (let j = 0; j < 7; j++) {
      let sunday = new Date(newDate.getTime());
      sunday.setDate(sunday.getDate() + j);
      weekDates.push(sunday);
    }
    return weekDates;
  }

  static get42Calendar(date: Date): Date[] {
    const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);

    const firstDayOfCalendar = new Date(firstDayOfMonth);
    firstDayOfCalendar.setDate(
      firstDayOfMonth.getDate() - firstDayOfMonth.getDay()
    );

    let currentDay = new Date(firstDayOfCalendar);
    const monthCalendar = [];
    while (monthCalendar.length < 42) {
      monthCalendar.push(new Date(currentDay));
      currentDay.setDate(currentDay.getDate() + 1);
    }

    return monthCalendar;
  }

  /**
   * Given a task and a date, it returns true if the task repeats on the given date
   * @param date
   * @param task
   * @private
   */
  private static doesRepeatOnDate(date: Date, task: TaskClass): boolean {
    if (!task.repeatInfo) return false;

    // if the task doesn't repeat during the date, return false
    const doesOccurDuringDate =
      task.startDate < DATE.getNextDayMidnight(date) &&
      task.getTerminatingDate() > date;
    if (!doesOccurDuringDate) return false;

    switch (task.repeatInfo.repeatType.type) {
      case "day":
        return true;
      case "week":
        return DATE.handleWeekRepeat(date, task);
      case "month":
        return false;
      case "year":
        return false;
    }

    return false;
  }

  /**
   * Given a date and a repeat info for a task, it returns true if the repeating task occurs on the date
   * This only accounts for the 'week' type
   * @param date
   * @param repeatInfo
   * @param startDate
   * @param endDate
   * @private
   */
  private static handleWeekRepeat(
    date: Date,
    { repeatInfo, startDate, endDate }: TaskClass
  ): boolean {
    if (repeatInfo?.repeatType.type !== "week")
      throw Error("Cannot handle a type other than 'week'");

    const weekDayIndex = date.getDay();
    const weekDayRepeatName = DATE.REPEAT_WEEK_DAYS[weekDayIndex];
    // TODO: account for skipping weeks
    // return true if the task repeats on the given date's day of the week
    return Boolean(repeatInfo.repeatType.weekDays[weekDayRepeatName]);
  }
}
