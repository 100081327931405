import styled from "styled-components";

export const ModalWrapper = styled.div<{ elevate: boolean }>`
  position: absolute;
  z-index: 2000;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 250ms;
  background-color: ${({ elevate }) =>
    elevate ? "rgba(0, 0, 0, 0.6)" : "transparent"};
  pointer-events: ${({ elevate }) => (elevate ? "unset" : "none")};

  :empty {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
  }
`;
