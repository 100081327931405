import styled from "styled-components";

export const RoleGroup = styled.section`
  width: 100%;
  border-bottom: 1px solid lightgrey;
  padding-bottom: 1rem;

  :last-of-type {
    border-bottom: none;
  }
`;

export const RoleGroupHeader = styled.h3`
  text-align: center;
  text-transform: capitalize;
  color: grey;
  font-size: 0.8rem;
  padding-bottom: 0.5rem;
`;

export const StyledAccount = styled.div`
  display: flex;
  gap: 0.5rem;
  min-height: 3rem;
  background-color: #fcfcfc;
  align-items: center;
  padding: 0.5rem;
  border-radius: 0.5rem;
`;

export const AccountName = styled.span`
  font-weight: 600;
  font-size: 1rem;
`;

export const AccountEmail = styled.span`
  color: grey;
  font-size: 0.9rem;
`;
