import styled from "styled-components";
import { DATE } from "../../../../logic/utils/date.module";

const CLOCK_BAR_HEIGHT = 2;

interface ClockBarProps {
  date: Date;
}

export const ClockBar = styled.div.attrs(({ date }: ClockBarProps) => ({
  style: { top: `${(DATE.getTotalMinutes(date) / DATE.MIN_PER_DAY) * 96}%` },
}))<ClockBarProps>`
  height: ${CLOCK_BAR_HEIGHT}px;
  width: 100%;
  background-color: rgba(255, 0, 0, 0.6);
  position: absolute;
  overflow: visible;
  z-index: 50;
  pointer-events: none;

  :before {
    background-color: ${({ theme }) => theme.colors.clockBar};
    display: block;
    width: 0.7rem;
    aspect-ratio: 1;
    content: " ";
    transform: translateY(calc(-50% + ${CLOCK_BAR_HEIGHT / 2}px))
      translateX(-50%);
    border-radius: 50%;
  }
`;
