import { FormRegistration } from "../../../logic/hooks/models/use-form.types";
import React from "react";
import { FieldError, FormInputWrapper } from "./form-input.styles";
import { DATE } from "../../../logic/utils/date.module";
import { StyleSize } from "../../../models/styles.types";
import { Flex } from "../../modal/modal-shared.styles";

export type FormInputType =
  | "text"
  | "email"
  | "textarea"
  | "password"
  | "checkbox"
  | "datetime-local"
  | "date"
  | "color"
  | "radio"
  | "number";

export interface FormInputStyledProps extends StyleSize {
  dir?: "col" | "row";
  hideError: boolean;
}

interface FormInputProps extends FormInputStyledProps {
  registration: FormRegistration;
  label?: string;
  placeholder?: string;
  required?: boolean;
  focus?: boolean;
  min?: Date | number | string;
}

const FormInputComponent = ({
  registration: { name, onChange, error, value, type },
  label,
  placeholder = "",
  dir,
  hideError = false,
  required = true,
  min = "",
  size,
}: FormInputProps) => {
  const sharedProps = {
    onChange,
    name,
    placeholder,
    required,
    value: value as string,
    id: name,
  };

  return (
    <FormInputWrapper
      dir={dir}
      error={Boolean(error)}
      type={type}
      size={size}
      hideError={hideError}
    >
      {label && <label htmlFor={name}>{label}</label>}
      <Flex dir={"column"}>
        {type === "textarea" ? (
          <textarea {...sharedProps}></textarea>
        ) : (
          <input
            autoFocus={false}
            {...sharedProps}
            type={type}
            checked={value as boolean}
            min={min instanceof Date ? DATE.toHTML_Datetime(min) : min}
          />
        )}
        {required && !hideError && <FieldError>{error}</FieldError>}
      </Flex>
    </FormInputWrapper>
  );
};

export default FormInputComponent;
