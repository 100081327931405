import React, { useId } from "react";
import {
  CheckboxContainer,
  HiddenCheckbox,
  Icon,
  StyledCheckbox,
} from "./form-checkbox.styles";
import { FormRegistration } from "../../../logic/hooks/models/use-form.types";

interface CheckboxProps {
  registration: Omit<FormRegistration, "type">;
  bgColor?: string;
  borderColor?: string;
  dummy?: boolean;
}

const Checkbox = ({
  registration: { name, value, onChange },
  bgColor,
  borderColor,
  dummy,
}: CheckboxProps) => {
  const id = useId();

  return (
    <CheckboxContainer>
      <HiddenCheckbox
        checked={value as boolean}
        name={name}
        onChange={onChange}
        id={id}
      />
      <StyledCheckbox
        checked={value as boolean}
        htmlFor={id}
        bgColor={bgColor}
        borderColor={borderColor}
        dummy={dummy}
      >
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
    </CheckboxContainer>
  );
};

export default Checkbox;
