import styled from "styled-components";

export const TaskModalFields = styled.fieldset`
  gap: 2rem;
  border: none;
  display: flex;
  max-height: 26rem;

  & > * {
    flex-shrink: 0;
  }
`;

export const TaskModalFormButtons = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: end;
  margin-top: 1rem;
`;

export const TaskModalFormColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex-grow: 1;
`;
export const TaskModalForm = styled.form`
  display: flex;
  flex-direction: column;
  padding: 3rem;
  border-radius: 1rem;
  background-color: white;
  width: calc(100vw - 2rem);
  max-width: 45rem;

  ${TaskModalFormColumn}:first-of-type {
    flex-basis: 50%;
  }

  @media (max-width: 767px) {
    gap: 1rem;
    max-width: 30rem;
    ${TaskModalFields} {
      flex-direction: column;
      overflow: auto;
    }

    ${TaskModalFormButtons} {
      justify-content: center;
    }

    ${TaskModalFormColumn}:first-of-type {
      flex-basis: unset;
    }
  }
`;
