import React from "react";
import { useCalendarsContext } from "../../../context/calendars.context";
import { usePresentationContext } from "../../../context/presentation/presentation.context";
import AddCalendarMenuComponent from "../../modal/add-calendar-modal/add-calendar-menu.component";
import CalendarGroupItemComponent from "./calendar-group-item/calendar-group-item.component";
import {
  AddCalendarButton,
  CalendarGroupHeader,
  CalendarGroupWrapper,
  CalendarList,
} from "./calendars-group.styles";
import { Add } from "@mui/icons-material";

function CalendarsGroupComponent() {
  const { calendars } = useCalendarsContext();
  const { displayContextMenu } = usePresentationContext();

  return (
    <CalendarGroupWrapper>
      <CalendarGroupHeader>
        <p>Calendars</p>
        <AddCalendarButton
          variant={"round"}
          onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
            displayContextMenu({
              menu: [],
              event,
              children: <AddCalendarMenuComponent />,
            })
          }
        >
          <Add fontSize={"small"} />
        </AddCalendarButton>
      </CalendarGroupHeader>
      <CalendarList>
        {Object.keys(calendars).map((calId) => (
          <CalendarGroupItemComponent calendar={calendars[calId]} key={calId} />
        ))}
      </CalendarList>
    </CalendarGroupWrapper>
  );
}

export default CalendarsGroupComponent;
