import { RepeatInfo, RepeatWeekDays, TaskDB, TaskType } from "../task.types";
import { TaskForm } from "../../components/modal/task-modal/task-modal.data";
import { DATE } from "../../logic/utils/date.module";
import { DocumentData } from "firebase/firestore";

export type RepeatInfoType = (RepeatInfo & { repeatEndDate: Date | "" }) | null;

export class TaskClass implements TaskDB {
  allDay: boolean;
  color: string;
  isComplete: boolean;
  meetingLink: string;
  repeatInfo: RepeatInfoType;
  startDate: Date;
  description: string;
  title: string;
  type: TaskType;
  endDate: Date;
  calendarId: string;
  id: string;

  private constructor(
    id: string,
    calendarId: string,
    title: string,
    allDay: boolean,
    color: string,
    isComplete: boolean,
    meetingLink: string,
    startDate: Date,
    description: string,
    type: TaskType,
    endDate: Date,
    repeatInfo: RepeatInfoType
  ) {
    this.id = id;
    this.calendarId = calendarId;
    this.title = title;
    this.allDay = allDay;
    this.color = color;
    this.isComplete = isComplete;
    this.meetingLink = meetingLink;
    this.startDate = startDate;
    this.description = description;
    this.type = type;
    this.endDate = endDate;
    this.repeatInfo = repeatInfo;
  }

  /**
   * Given a task form it returns the repeatInfo object for the task
   * @param formData
   */
  public static formToRepeatType(formData: TaskForm) {
    if (!formData.repeat) return null;

    const repeatType =
      formData.repeatType === "week"
        ? {
            type: "week" as const,
            weekDays: DATE.REPEAT_WEEK_DAYS.reduce<RepeatWeekDays>(
              (accum, weekDay) => {
                accum[weekDay] = formData[weekDay];
                return accum;
              },
              {}
            ),
          }
        : { type: formData.repeatType };

    const repeatInfo: RepeatInfoType = {
      repeatEvery: formData.repeatEvery,
      instanceEndDate: formData.endDate,
      repeatEndDate: formData.repeatEnd,
      repeatType,
    };

    return repeatInfo;
  }

  /**
   * Given a task form map from a ui form it returns a TaskClass instance
   * @param formData
   */
  public static formToTask(formData: TaskForm): TaskClass {
    const repeatInfo = TaskClass.formToRepeatType(formData);

    return new TaskClass(
      "",
      formData.calendarId,
      formData.title,
      formData.allDay,
      formData.color,
      formData.isComplete,
      formData.meetingLink,
      formData.startDate,
      formData.description,
      formData.type,
      formData.endDate,
      repeatInfo
    );
  }

  /**
   * Converts a firestore Task document to a TaskClass instance
   * @param id
   * @param calendarId
   * @param document
   */
  public static fromFirestore(
    id: string,
    calendarId: string,
    document: DocumentData
  ): TaskClass {
    const __endDate__ = document.endDate.toDate();
    const repeatInfo: RepeatInfoType = document.repeatInfo
      ? {
          ...document.repeatInfo,
          instanceEndDate: document.repeatInfo.instanceEndDate.toDate(),
          repeatEndDate: __endDate__,
        }
      : null;
    // if the task repeats, we use the instanceEndDate, otherwise we use the end date
    const endDate: Date = repeatInfo ? repeatInfo.instanceEndDate : __endDate__;
    return new TaskClass(
      id,
      calendarId,
      document.title,
      document.allDay,
      document.color,
      document.isComplete,
      document.meetingLink,
      document.startDate.toDate(),
      document.description,
      document.type,
      endDate,
      repeatInfo
    );
  }

  /**
   * Converts a TaskClass instance to a TaskDB object ready to be stored in the database
   * @param task
   */
  public static toFirestore(task: TaskClass): TaskDB {
    let repeatInfo: RepeatInfo | null = null;
    let endDate: Date = task.endDate;
    if (task.repeatInfo) {
      repeatInfo = {
        ...task.repeatInfo,
        // when saving to the database, the instanceEndDate must be set to endDate if the task is repeating
        instanceEndDate: task.endDate,
      };
      // when saving to the database, the end date must be set to the repeat end if the task is repeating
      // this makes it easier to query future tasks based on the endDate field
      endDate = task.repeatInfo.repeatEndDate || DATE.getMaxPossibleDate();
    }
    // the end date must be set to the repeatingEnd if the task repeats
    return {
      color: task.color,
      meetingLink: task.meetingLink,
      type: task.type,
      title: task.title,
      startDate: task.startDate,
      endDate,
      description: task.description,
      allDay: task.allDay,
      isComplete: task.isComplete,
      repeatInfo,
    };
  }

  /**
   * If the task repeats it returns the repeatEndDate, otherwise it returns the end date
   */
  public getTerminatingDate(): Date {
    return this.repeatInfo?.repeatEndDate || this.endDate;
  }
}
