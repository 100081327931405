import React from "react";
import { usePresentationContext } from "../../../context/presentation/presentation.context";
import { VIEW_TYPES } from "../../../constants/types";
import { DateActions } from "../../../models/date.model";
import { useDateContext, useDateDispatch } from "../../../context/date.context";
import { ViewToggleButton } from "./view-toggle-button.styles";

function ViewToggleButtonComponent() {
  const { displayContextMenu } = usePresentationContext();
  const dispatchDate = useDateDispatch();
  const { currentView } = useDateContext();

  function handleViewTypeSelection(viewType: string) {
    dispatchDate({
      type: DateActions.UpdateViewType,
      payload: { currentView: viewType },
    });
  }

  function handleShowingContextMenu(
    event: React.MouseEvent<HTMLButtonElement>
  ) {
    displayContextMenu({
      event,
      alignToEdge: true,
      marginTop: 5,
      menu: VIEW_TYPES.toArray().map((viewType) => {
        return {
          text: viewType,
          onClick: () => handleViewTypeSelection(viewType),
        };
      }),
    });
  }

  return (
    <ViewToggleButton
      onClick={handleShowingContextMenu}
      bgColor={"white"}
      color={"black"}
      type={"button"}
    >
      {currentView}
    </ViewToggleButton>
  );
}

export default ViewToggleButtonComponent;
