import styled from "styled-components";

export const GridColorPicker = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  max-width: 10rem;
  padding: 0.5rem;
  background: white;
`;

export const ColorOption = styled.label<{ color: string; selected: boolean }>`
  background-color: ${({ color }) => color};
  width: 1.4rem;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  border: ${({ selected }) => `2px solid ${selected ? "#000" : "transparent"}`};
  transition: border 150ms;

  :hover {
    border: ${({ selected }) => `2px solid ${selected ? "#000" : "#00F"}`};
  }
`;
