import React from "react";
import { Flex } from "../modal-shared.styles";
import FormInputComponent from "../../form/form-input/form-input.component";
import { FormButton } from "../../form/form.styles";
import { TaskType } from "../../../models/task.types";
import FormSelectComponent from "../../form/form-select/form-select.component";
import {
  TaskModalFields,
  TaskModalForm,
  TaskModalFormButtons,
  TaskModalFormColumn,
} from "./task-modal.styles";
import TaskRepeatComponent from "./task-repeat/task-repeat.component";
import useTaskModal from "./task-modal.hook";
import { TaskClass } from "../../../models/classes/task.class";

export type TaskModalPrePopulate =
  | { isEditing: true; task: TaskClass }
  | { isEditing?: false; task: Partial<TaskClass> }
  | undefined;

export interface TaskModalProps {
  closeModal: () => void;
  prePopulate: TaskModalPrePopulate;
}

function TaskModalComponent(props: TaskModalProps) {
  const {
    register,
    onSubmit,
    userCanOnlyViewTask,
    getEndDateBasedOnStartDate,
    handleTaskDeletion,
    calendarOptions,
    isEditing,
    formData,
  } = useTaskModal(props);

  return (
    <TaskModalForm noValidate={true} onSubmit={onSubmit}>
      <TaskModalFields disabled={userCanOnlyViewTask()}>
        <TaskModalFormColumn>
          <FormInputComponent
            hideError={false}
            registration={register("title", "text")}
            placeholder={"Task name"}
          />
          <FormInputComponent
            placeholder={"Add description..."}
            required={false}
            hideError={true}
            registration={register("description", "textarea")}
          />
          <Flex gap={"1rem"}>
            <FormSelectComponent
              label={"Select calendar"}
              disabled={isEditing}
              options={calendarOptions}
              registration={register("calendarId", "text")}
            />
            {/* // REFACTOR: Once calendar categories or subtasks are implemented */}
            {/*<GridColorPickerComponent*/}
            {/*  registration={register("color")}*/}
            {/*  colors={COLOR_PICKER_COLORS}*/}
            {/*/>*/}
          </Flex>
          <FormInputComponent
            registration={register("meetingLink", "text")}
            required={false}
            placeholder={"Meeting link"}
            hideError={true}
          />
        </TaskModalFormColumn>
        <TaskModalFormColumn>
          <FormInputComponent
            registration={register("startDate", "datetime-local", {
              // set the end date to an hour from the start date when the start date is changed
              sideEffect: {
                name: "endDate",
                value: getEndDateBasedOnStartDate(),
              },
            })}
            label={"Start date"}
            hideError={false}
          />
          <FormInputComponent
            registration={register("endDate", "datetime-local")}
            label={"End date"}
            min={formData.startDate as Date}
            hideError={false}
          />
          <Flex gap={"1rem"}>
            <FormSelectComponent
              options={Object.values(TaskType).map((val) => ({ text: val }))}
              registration={register("type", "text")}
            />
            <FormInputComponent
              label={"All day"}
              dir={"row"}
              required={false}
              hideError={true}
              registration={register("allDay", "checkbox")}
            />
            <FormInputComponent
              label={"Completed"}
              dir={"row"}
              required={false}
              hideError={true}
              registration={register("isComplete", "checkbox")}
            />
          </Flex>
          <TaskRepeatComponent register={register} formData={formData} />
        </TaskModalFormColumn>
      </TaskModalFields>
      <TaskModalFormButtons>
        {isEditing && (
          <FormButton
            type={"button"}
            bgColor={"red"}
            onClick={handleTaskDeletion}
          >
            Delete
          </FormButton>
        )}
        <FormButton type={"submit"}>
          {isEditing ? "Confirm" : "Create"}
        </FormButton>
      </TaskModalFormButtons>
    </TaskModalForm>
  );
}

export default TaskModalComponent;
