import React, { useRef } from "react";
import {
  SideMenu,
  SideMenuOption,
  SideMenuWrapper,
  ViewToggleListButtons,
} from "./side-menu.styles";
import MiniCalendarComponent from "./mini-calendar/mini-calendar.component";
import CalendarsGroupComponent from "./calendars-group/calendars-group.component";
import { VIEW_TYPES } from "../../constants/types";
import { useDateContext, useDateDispatch } from "../../context/date.context";
import { DateActions } from "../../models/date.model";

interface SideMenuProps {
  isOpen: boolean;
  closeSideMenu: () => void;
}

function SideMenuComponent({ isOpen, closeSideMenu }: SideMenuProps) {
  const sideMenuRef = useRef<HTMLDivElement>(null);
  const { currentView } = useDateContext();
  const dispatchDate = useDateDispatch();
  return (
    <SideMenuWrapper
      isOpen={isOpen}
      onClick={(e) => {
        // if the click is outside the side menu, then close the side menu
        // this is only important on small screens since clicking the side
        if (!sideMenuRef.current!.contains(e.target as HTMLElement)) {
          closeSideMenu();
        }
      }}
    >
      <SideMenu ref={sideMenuRef}>
        <MiniCalendarComponent />
        {/* only displayed on mobile */}
        <ViewToggleListButtons>
          {VIEW_TYPES.toArray().map((viewType) => {
            return (
              <SideMenuOption
                selected={viewType === currentView}
                key={viewType}
                onClick={() => {
                  dispatchDate({
                    type: DateActions.UpdateViewType,
                    payload: { currentView: viewType },
                  });
                  closeSideMenu();
                }}
              >
                {viewType}
              </SideMenuOption>
            );
          })}
        </ViewToggleListButtons>
        <CalendarsGroupComponent />
      </SideMenu>
    </SideMenuWrapper>
  );
}

export default SideMenuComponent;
