import { useEffect } from "react";
import { useCalendarsContext } from "../../../context/calendars.context";
import { RoleType } from "../../../models/calendar";
import useForm from "../../../logic/hooks/useForm";
import {
  blankTask,
  mergeTaskFormWithTask,
  TaskForm,
  taskValidationSchema,
} from "./task-modal.data";
import { useAuth } from "../../../context/auth.context";
import { Task, TaskDB } from "../../../models/task.types";
import {
  createTaskDB,
  deleteTaskDB,
  updateTaskDB,
} from "../../../logic/firebase/db-service/calendars/tasks/tasks.db";
import { DATE } from "../../../logic/utils/date.module";
import { TaskModalProps } from "./task-modal.component";
import { TaskClass } from "../../../models/classes/task.class";

function useTaskModal({ prePopulate, closeModal }: TaskModalProps) {
  const { calendars } = useCalendarsContext();
  const isEditing = Boolean(prePopulate?.isEditing);
  const calendarOptions = Object.keys(calendars)
    .filter((id) => {
      // allow all calendar options when editing since the field is disabled
      return isEditing || calendars[id].role !== RoleType.Viewer;
    })
    .map((id) => ({
      text: calendars[id].name,
      value: id,
    }));

  const { formData, handleSubmit, register, carefullyUpdateVal } = useForm(
    mergeTaskFormWithTask(blankTask, prePopulate?.task),
    taskValidationSchema
  );
  const currentUser = useAuth();

  useEffect(() => {
    if (!formData.calendarId) {
      carefullyUpdateVal("calendarId", calendarOptions[0].value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendars]);

  const onSubmit = async (formData: TaskForm): Promise<boolean> => {
    try {
      if (isEditing) {
        await handleTaskEdition(formData);
        closeModal();
        return false;
      } else {
        await handleTaskCreation(formData);
        closeModal();
        return true;
      }
    } catch (e: any) {
      throw e;
    }
  };

  const handleTaskEdition = async (formData: TaskForm) => {
    if (!currentUser) throw Error("Must be logged in first");
    else if (prePopulate === undefined || !prePopulate.isEditing)
      throw Error("Failed to edit in create mode");
    else if (!prePopulate?.task.id)
      throw Error("Failed to edit. Task id is missing");

    const keysToSkip = ["id", "calendarId", "repeatInfo"];
    const taskKeys = Object.keys(prePopulate.task).filter(
      (key) => !keysToSkip.includes(key)
    ) as (keyof Task)[];
    const fieldsToUpdate: Partial<TaskDB> = taskKeys.reduce(
      (accum, currKey) => {
        if (currKey === "repeatInfo") return accum;
        const prevVal = prePopulate.task[currKey];
        const newVal = formData[currKey];

        if (prevVal !== newVal)
          return {
            ...accum,
            [currKey]: formData[currKey],
          };
        else return accum;
      },
      {}
    );

    const repeatInfo = TaskClass.formToRepeatType(formData);
    if (repeatInfo) {
      // @ts-ignore
      delete repeatInfo.repeatEndDate;
    }
    fieldsToUpdate.repeatInfo = repeatInfo;
    if (formData.repeat) {
      fieldsToUpdate.endDate = formData.repeatEnd
        ? formData.repeatEnd
        : DATE.getMaxPossibleDate();
    } else {
      fieldsToUpdate.endDate = formData.endDate;
    }

    // TODO: double check this optimization because right now we are always sending an update even
    // when nothing changes
    if (!Object.keys(fieldsToUpdate).length) return;

    await updateTaskDB(currentUser.uid, {
      id: prePopulate.task.id,
      calendarId: prePopulate.task.calendarId,
      fieldsToUpdate,
    });
  };

  const handleTaskCreation = async (taskForm: TaskForm) => {
    if (!currentUser) throw Error("Must be logged in first");
    if (taskForm.startDate > taskForm.endDate)
      throw Error("End date must be before start date");

    // const newTask: TaskDB = {
    //   title: task.title,
    //   type: task.type as TaskType,
    //   description: task.description,
    //   isComplete: task.isComplete,
    //   color: task.color,
    //   meetingLink: task.meetingLink,
    //   startDate: task.startDate,
    //   endDate: task.endDate,
    //   allDay: task.allDay,
    // };
    //
    // _setTaskRepeatInfo(newTask);

    await createTaskDB(
      currentUser.uid,
      taskForm.calendarId,
      TaskClass.formToTask(taskForm)
    );
  };

  // const _setTaskRepeatInfo = (newTask: Partial<TaskDB>) => {
  //   if (formData.repeat) {
  //     // the task end date becomes the repeat end date. This makes it easier to query the db based on the end date
  //     // the instanceEndDate is what we use to store the end of the individual task's duration
  //     newTask.endDate = formData.repeatEnd
  //       ? formData.repeatEnd
  //       : DATE.getMaxPossibleDate();
  //     newTask.repeatInfo = {
  //       repeatType: { type: formData.repeatType },
  //       repeatEvery: formData.repeatEvery,
  //       // used to determine the duration of the instance task
  //       instanceEndDate: formData.endDate,
  //     };
  //     if (newTask?.repeatInfo?.repeatType.type === "week") {
  //       const weekDays = DATE.REPEAT_WEEK_DAYS.reduce<RepeatWeekDays>(
  //         (accum, weekDay) => {
  //           accum[weekDay] = formData[weekDay];
  //           return accum;
  //         },
  //         {}
  //       );
  //       newTask.repeatInfo.repeatType = { type: "week", weekDays };
  //     }
  //   } else {
  //     // if repeating is disabled, change the task.endDate back to use the endDate field
  //     newTask.endDate = formData.endDate;
  //     newTask.repeatInfo = null;
  //   }
  // };

  const handleTaskDeletion = async () => {
    if (!currentUser || !prePopulate?.isEditing)
      throw Error("Failed to delete task");
    try {
      await deleteTaskDB(
        currentUser?.uid,
        prePopulate.task.calendarId,
        prePopulate.task.id
      );
      closeModal();
    } catch (e: any) {
      throw e;
    }
  };

  const userCanOnlyViewTask = (): boolean => {
    if (prePopulate?.isEditing) {
      return calendars[prePopulate.task.calendarId].role === RoleType.Viewer;
    }
    return false;
  };

  const getEndDateBasedOnStartDate = (): Date => {
    const { startDate, endDate } = formData;
    // if the user changes the start date to after the end date
    // we automatically update the end date to an hour after the start date
    if (startDate < endDate) return endDate;
    return DATE.timeFromDate(1, 0, startDate);
  };

  return {
    register,
    userCanOnlyViewTask,
    getEndDateBasedOnStartDate,
    handleTaskDeletion,
    isEditing,
    calendarOptions,
    formData,
    onSubmit: handleSubmit(onSubmit),
  } as const;
}

export default useTaskModal;
