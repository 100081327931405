import styled, { css } from "styled-components";
import { DATE } from "../../../../logic/utils/date.module";
import { StyledBaseTask } from "../task/task.styles";

export const DynamicTask = styled(StyledBaseTask)<{
  columnIndex: number;
  columnsCount: number;
  startDate: Date;
  endDate?: Date;
  type: string;
}>`

  font-size: 0.65rem;
  position: absolute;
  padding: 0.5rem 0.3rem;
  gap: 0.5rem;

  top: ${({ startDate }) => {
    const MIN_PER_DAY = DATE.TIME_SLOTS.length * 60;
    const minutes = startDate.getHours() * 60 + startDate.getMinutes();
    const percentage = (minutes / MIN_PER_DAY) * 100;
    return `${Math.min(percentage, 97)}%`;
  }};
  height: ${({ startDate, endDate }) => {
    const DEFAULT_DURATION = DATE.ONE_HOUR_MS; // One hour in milliseconds
    // @ts-ignore
    const duration = endDate ? endDate - startDate : DEFAULT_DURATION;
    const heightPercentage =
      (duration / (DATE.TIME_SLOTS.length * DATE.ONE_HOUR_MS)) * 100; // Total day in milliseconds
    return `${heightPercentage}%`;
  }};


  ${({ columnsCount, columnIndex }) => css`
    --gap: 5px;
    --width-decrease: calc(
      (var(--gap) * ${columnsCount + 1}) / ${columnsCount}
    );
    --width: calc(min(100% / ${columnsCount}, 15rem) - var(--width-decrease));
    width: var(--width);
    left: calc(
      var(--width) * ${columnIndex} + (${columnIndex + 1} * var(--gap))
    );
  `}
}


;
`;

export const DynamicTaskInnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;

  & > svg {
    visibility: hidden;
  }

  &:hover {
    cursor: pointer;

    & > svg {
      visibility: visible;
    }
  }
`;

export const TaskTitle = styled.h4``;

export const TaskContent = styled.div``;
