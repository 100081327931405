import styled from "styled-components";
import { TOP_BAR_HEIGHT } from "../../constants/theme/theme";
import { ViewToggleButton } from "./view-toggle-button/view-toggle-button.styles";

export const MonthYearContainer = styled.div`
  display: flex;
  font-size: 1rem;
  gap: 0.5rem;
  font-weight: 500;
`;

export const AppTitle = styled.h1`
  font-size: 1.2rem;
  line-height: 1.75rem;
`;
export const TopBar = styled.div`
  background-color: ${({ theme }) => theme.colors.topBar};
  height: ${TOP_BAR_HEIGHT};
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding-inline: 0.5rem;

  @media (max-width: 570px) {
    ${MonthYearContainer} {
      p:nth-child(2) {
        display: none;
      }
    }

    ${AppTitle} {
      display: none;
    }

    ${ViewToggleButton} {
      display: none;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
`;

export const RightEdgeContainer = styled.div`
  display: flex;
  margin-left: auto;
  gap: 0.75rem;
  align-items: center;
`;
