import styled from "styled-components";

export const Modal = styled.div`
  background-color: white;
  padding: 1rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export interface StyledButton {
  bgColor?: string;
  color?: string;
}

export const ModalButton = styled.button<StyledButton>`
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  border: none;
  background-color: ${({ bgColor }) => bgColor || "darkblue"};
  color: ${({ color }) => color || "white"};
  cursor: pointer;
  min-width: 4rem;
`;

export const Flex = styled.div<{
  alignSelf?: "flex-end";
  alignItems?: "center";
  justifyContent?: "center" | "start";
  dir?: "row" | "column";
  gap?: string;
  mt?: string;
  mr?: string;
  ml?: string;
  mb?: string;
  minH?: string;
  minW?: string;
  bgColor?: string;
}>`
  display: flex;
  margin-top: ${({ mt }) => mt};
  margin-bottom: ${({ mb }) => mb};
  margin-left: ${({ ml }) => ml};
  margin-right: ${({ mr }) => mr};
  align-self: ${({ alignSelf }) => alignSelf};
  align-items: ${({ alignItems }) => alignItems};
  flex-direction: ${({ dir }) => dir};
  justify-content: ${({ justifyContent }) => justifyContent};
  gap: ${({ gap }) => gap};
  min-height: ${({ minH }) => minH};
  background-color: ${({ bgColor }) => bgColor};
  min-width: ${({ minW }) => minW};
  min-height: ${({ minH }) => minH};
`;
