import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { ModalWrapper } from "./presentation.styles";
import {
  ModalAction,
  ModalName,
  PresentationContextValue,
} from "../../models/modal.model";
import ShareCalendarModalComponent from "../../components/modal/share-calendar-modal/share-calendar-modal.component";
import {
  ContextMenuPayload,
  ContextMenuState,
} from "../../models/context-menu.model";
import ConfirmModalComponent from "../../components/modal/confirm-modal/confirm-modal.component";
import TaskModalComponent from "../../components/modal/task-modal/task-modal.component";
import ContextMenuComponent from "../../components/context-menu/context.menu.component";

const PresentationContext = createContext<null | PresentationContextValue>(
  null
);

export const usePresentationContext = (): PresentationContextValue =>
  useContext(PresentationContext)!;

function PresentationContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const contextMenuRef = useRef<HTMLDivElement>(null);
  const [modals, setModals] = useState<ModalAction[]>(
    Object.values(ModalName).map<ModalAction>((model) => ({
      show: false,
      type: model,
      data: undefined,
    }))
  );

  const [contextMenu, setContextMenu] = useState<ContextMenuState | null>(null);

  useEffect(() => {
    const handleClick = () => {
      setContextMenu(null);
    };

    if (contextMenu) {
      setTimeout(() => {
        document.addEventListener("click", handleClick);
      }, 0);
    }
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [contextMenu]);

  const closeModals = () => {
    setModals(
      modals.map<ModalAction>((modal) => ({
        show: false,
        data: undefined,
        type: modal.type,
      }))
    );
  };

  const toggleModalVisibility = ({ type, show, data }: ModalAction) => {
    if (modals.find((modal) => modal.type === type) === undefined)
      throw new Error(`Can't toggle visibility of ${type} modal`);
    setModals(
      modals.map<ModalAction>((modal) =>
        // @ts-ignore
        modal.type === type ? { ...modal, show, data } : modal
      )
    );
  };

  const renderModal = (modal: ModalAction) => {
    switch (modal.type) {
      case ModalName.TASK:
        return (
          <TaskModalComponent
            closeModal={closeModals}
            prePopulate={modal.data}
          />
        );
      case ModalName.SHARE_CALENDAR:
        return (
          <ShareCalendarModalComponent calendarId={modal.data?.calendarId!} />
        );
      case ModalName.CONFIRM:
        if (modal.data) {
          return <ConfirmModalComponent {...modal.data} close={closeModals} />;
        } else return null;
      default:
        return null;
    }
  };

  const displayContextMenu = ({ event, ...props }: ContextMenuPayload) => {
    const { currentTarget, clientX, clientY } = event;
    setContextMenu({ currentTarget, clientX, clientY, ...props });
  };

  const closeContextMenus = () => {
    setContextMenu(null);
  };

  const renderModals = () => {
    return modals
      .filter((modal) => modal.show)
      .map((modalAction) => {
        const modal = renderModal(modalAction);
        return (
          modal && (
            <div
              key={modalAction.type}
              onMouseDown={(e) => e.stopPropagation()}
            >
              {modal}
            </div>
          )
        );
      });
  };

  return (
    <PresentationContext.Provider
      value={{
        toggleModalVisibility,
        displayContextMenu,
        closeModals,
        closeContextMenus,
      }}
    >
      <ModalWrapper onMouseDown={closeModals} elevate={true}>
        {renderModals()}
      </ModalWrapper>
      <ModalWrapper elevate={false}>
        {contextMenu && (
          <ContextMenuComponent ref={contextMenuRef} {...contextMenu} />
        )}
      </ModalWrapper>
      {children}
    </PresentationContext.Provider>
  );
}

export default PresentationContextProvider;
