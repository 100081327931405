import { ThemeProvider } from "styled-components";
import theme from "./constants/theme/theme";
import DateContextProvider from "./context/date.context";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ApplicationPage from "./pages/root/application.page";
import PrivateRoutes from "./pages/private-routes";
import { AuthProvider } from "./context/auth.context";
import SignupPage from "./pages/signup/signup.page";
import TasksContextProvider from "./context/tasks.context";
import CalendarsContextProvider from "./context/calendars.context";
import PresentationContextProvider from "./context/presentation/presentation.context";
import UsersContextProvider from "./context/users.context";
import React from "react";
import LandingPage from "./pages/landing/landing.page";
import LoginPage from "./pages/login/login.page";

function App() {
  return (
    <>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Routes>
              <Route element={<PrivateRoutes />}>
                <Route
                  path="/"
                  element={
                    <DateContextProvider>
                      <CalendarsContextProvider>
                        <TasksContextProvider>
                          <UsersContextProvider>
                            <PresentationContextProvider>
                              <ApplicationPage />
                            </PresentationContextProvider>
                          </UsersContextProvider>
                        </TasksContextProvider>
                      </CalendarsContextProvider>
                    </DateContextProvider>
                  }
                />
              </Route>
              <Route element={<LandingPage />} path="/home" />
              <Route element={<LoginPage />} path="/login" />
              <Route element={<SignupPage />} path="/signup" />
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      </AuthProvider>
    </>
  );
}

export default App;
