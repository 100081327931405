import styled from "styled-components";
import theme, { TIME_BAR_WIDTH } from "../../../../constants/theme/theme";

export const TimeBar = styled.div`
  display: flex;
  flex-direction: column;
  width: ${TIME_BAR_WIDTH};
  height: 100%;
  border-right: 1px solid ${theme.colors.gridBorders};
  background: ${theme.colors.contentBackground};
`;

export const TimeBarStamp = styled.div`
  font-size: 0.6rem;
  display: flex;
  justify-content: center;
  height: ${theme.spacing.timeSlotHeight};
`;
