import styled from "styled-components";
import React from "react";

export interface IconButtonProps {
  children: React.ReactNode;
  variant: "round" | "square";
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  size?: string;
  ml?: string;
  bg?: string;
  hoverBg?: string;
  title?: string;
}

export const IconButton = styled.button<IconButtonProps>`
  border: none;
  border-radius: ${({ theme, variant }) =>
    variant === "round" ? "50%" : theme.spacing.borderRadius};
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  display: grid;
  place-items: center;
  cursor: pointer;
  flex-shrink: 0;
  transition: background-color 150ms;
  margin-left: ${({ ml }) => ml};
  background-color: ${({ bg }) => bg || "transparent"};

  :hover {
    background-color: ${({ hoverBg }) => hoverBg || "rgba(0, 0, 0, 0.1)"};
  }
`;
