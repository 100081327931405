export enum DateActions {
  NextView = "NextView",
  PrevView = "PrevView",
  UpdateViewType = "UpdateViewType",
  JumpToDate = "JumpToDate",
  UpdateTasksRange = "UpdateTasksRange",
}

export type DateAction =
  | JumpToDateAction
  | UpdateTasksRangeAction
  | NextPrevAction;

export interface DateActionBase {
  type: DateActions;
}

export interface GeneralDatePayload {
  currentView?: string;
  date?: Date;
  step?: number;
}

export interface JumpToDateAction extends DateActionBase {
  type: DateActions.JumpToDate | DateActions.UpdateViewType;
  payload: GeneralDatePayload;
}

export interface NextPrevAction {
  type: DateActions.PrevView | DateActions.NextView;
}

export interface UpdateTaskRangePayload {
  startDate: Date;
  endDate: Date;
}

export interface UpdateTasksRangeAction extends DateActionBase {
  type: DateActions.UpdateTasksRange;
  payload: UpdateTaskRangePayload;
}

export interface DateContextState {
  dates: Date[];
  currentView: string;
}

export type DateDispatch = (action: DateAction) => void;
