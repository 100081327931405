import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../../firebase";
import { CalendarMap } from "../../../../../models/calendar";
import { TaskDB, TaskUpdatePayload } from "../../../../../models/task.types";
import { TaskClass } from "../../../../../models/classes/task.class";

export const createTaskDB = async (
  uid: string,
  calendarId: string,
  task: TaskClass
) => {
  if (!uid) throw new Error("No user id provided for new task");
  const dbTask: TaskDB = TaskClass.toFirestore(task);
  // addDoc doesn't need a doc id, vs setDoc needs a doc id
  await addDoc(collection(db, "calendars", calendarId, "tasks"), dbTask);
};
export const updateTaskDB = async (uid: string, payload: TaskUpdatePayload) => {
  if (!uid) throw new Error("No user id provided for new task");
  const { id, calendarId, fieldsToUpdate } = payload;
  const docRef = doc(db, "calendars", calendarId, "tasks", id);
  await updateDoc(docRef, fieldsToUpdate);
};

export const deleteTaskDB = async (
  uid: string,
  calendarId: string,
  id: string
) => {
  if (!uid) throw new Error("No user id provided for new task");
  const docRef = doc(db, "calendars", calendarId, "tasks", id);
  await deleteDoc(docRef);
};

export const subscribeTasksOccurringDuringInterval = (
  calendars: CalendarMap,
  intervalStartDate: Date,
  intervalEndDate: Date,
  callback: (calendarId: string, tasks: TaskClass[]) => void
) => {
  // Start date at 12:00 AM
  const start = new Date(intervalStartDate);
  start.setHours(0, 0, 0, 0);

  // End date + 1 at 12:00 AM
  const end = new Date(intervalEndDate);
  end.setHours(0, 0, 0, 0);
  end.setDate(end.getDate() + 1);

  const unsubscribeFunctions = Object.keys(calendars).map((calendarId) => {
    // given a time frame startDate ---- endDate,
    // return the tasks for the given calendar that end on the start of the view or later.
    // this will ensure we get tasks that might have started before this view
    // but are still on going. Downside is having to over-fetch date because we are getting all future tasks
    const tasksQuery = query(
      collection(db, "calendars", calendarId, "tasks"),
      where("endDate", ">=", start)
    );

    const unsub = onSnapshot(tasksQuery, (snapshot) => {
      const tasks: TaskClass[] = [];
      snapshot.docs.forEach((doc) => {
        const taskData = doc.data();
        const startDate: Date = taskData.startDate.toDate();
        // const dbTask = taskData as TaskDB;
        // filter out tasks that don't start until after the current view
        if (startDate < end) {
          // const task: Task = {
          //   ...dbTask,
          //   startDate,
          //   endDate: taskData.endDate.toDate(),
          //   id: doc.id,
          //   calendarId: calendarId,
          // };
          //
          // if (dbTask.repeatInfo) {
          //   task.repeatInfo = {
          //     ...dbTask.repeatInfo,
          //     instanceEndDate: taskData.repeatInfo.instanceEndDate.toDate(),
          //   };
          // }
          const task = TaskClass.fromFirestore(doc.id, calendarId, taskData);
          tasks.push(task);
        }
      });
      callback(calendarId, tasks);
    });

    return unsub;
  });

  return () => {
    unsubscribeFunctions.forEach((unsubscribe) => unsubscribe());
  };
};
