import styled from "styled-components";
import { WeekDays } from "../week-view/week-view.styles";

export const DayGrid = styled(WeekDays)`
  flex-direction: column;
  position: relative;
`;

export const DayViewHeader = styled.div`
  background-color: ${({ theme }) => theme.colors.headers};
  height: 3.5rem;
  display: flex;
  align-items: center;
`;

export const DayViewDate = styled.div<{ highlight: boolean }>`
  border-radius: 50%;
  aspect-ratio: 1;
  padding: 0.5rem;
  height: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.15rem;
  margin-left: 0.25rem;
  font-size: 0.7rem;
  background-color: ${({ highlight, theme }) =>
    highlight && theme.colors.currentDay};
  color: ${({ highlight }) => highlight && "white"};

  span:nth-child(2) {
    font-size: 1rem;
  }
`;
