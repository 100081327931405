import styled, { css } from "styled-components";
import { AllDayInfo } from "./task.component";
import { getBackgroundColor } from "../../../../logic/utils/color.utils";
// REFACTOR AFTER THEME IS IMPLEMENTED
import { theme } from "../../../grid-color-picker/grid-color-picker.component";

// REFACTOR AFTER THEME IS IMPLEMENTED
const completeColor: string = "#32CD32";

export const StyledBaseTask = styled.li<{
  calColor: string;
  isComplete: boolean;
}>`
  list-style: none;
  border-radius: 0.3rem;
  display: flex;
  color: ${({ isComplete, calColor }) => (isComplete ? "grey" : calColor)};
  //  REFACTOR AFTER THEME IS IMPLEMENTED
  border: ${({ isComplete, calColor }) =>
    `2px solid ${isComplete ? completeColor : calColor}`};
  text-decoration: ${({ isComplete }) => isComplete && "line-through"};
  background-color: ${({ isComplete, calColor }) =>
    isComplete
      ? getBackgroundColor(completeColor, theme)
      : getBackgroundColor(calColor, theme)};
`;

export const StyledTask = styled(StyledBaseTask)<{
  type: string;
  allDayInfo?: AllDayInfo;
  size?: "sm" | "lg";
}>`
  font-size: 0.75rem;
  align-items: center;
  justify-content: space-between;
  gap: 0.3rem;
  height: ${({ size }) => ({ sm: "1.5rem", lg: "2.5rem" }[size || "sm"])};
  white-space: nowrap;
  overflow: hidden;

  ${({ allDayInfo }) => {
    if (!allDayInfo) return `width: 100%;`;

    const { dates, task } = allDayInfo;

    const viewStartDate = dates.at(0);
    const viewEndDate = dates.at(-1);

    let taskStartDate = viewStartDate;
    if (!viewStartDate || task.startDate >= viewStartDate) {
      taskStartDate = new Date(task.startDate);
      taskStartDate.setHours(0, 0, 0, 0);
    }

    let taskEndDate = new Date(viewEndDate!);
    if (viewEndDate === undefined || task.endDate < viewEndDate) {
      taskEndDate = new Date(task.endDate);
    }
    taskEndDate.setHours(0, 0, 0, 0);
    taskEndDate.setDate(taskEndDate.getDate() + 1);

    // @ts-ignore
    const widthDuration = taskEndDate - taskStartDate;
    // seven days in millseconds
    const sevenDays = 7 * 24 * 60 * 60 * 1000;
    const widthPercentage = (widthDuration / sevenDays) * 100;

    // @ts-ignore
    const leftDuration = taskStartDate - viewStartDate;
    const leftPercentage = (leftDuration / sevenDays) * 100;

    return css`
      width: ${widthPercentage}%;
      margin-left: ${leftPercentage}%;
    `;
  }};

  & > svg {
    visibility: hidden;
  }

  &:hover {
    cursor: pointer;

    & > svg {
      visibility: visible;
    }
  }
`;

export const TaskContentWrapper = styled.div`
  display: flex;
  gap: 0.3rem;
  padding-inline: 0.3rem;
`;

export const TaskTimestamp = styled.span`
  min-width: fit-content;
  text-align: end;
`;

export const MonthTaskTitle = styled.span`
  font-weight: 500;
  text-overflow: ellipsis;
`;
