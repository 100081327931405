import React from "react";
import { UserProfile } from "../../../../models/user.model";
import { Circle } from "../../../../styles.global";
import { Flex } from "../../modal-shared.styles";
import { getInitials } from "../../../../logic/utils/text.utils";
import { AccountEmail, AccountName, StyledAccount } from "./account.styles";
import IconComponent from "../../../../icons/icon.component";
import { ICONS } from "../../../../icons/icons";

interface AccountProps {
  userProfile: UserProfile | undefined;
}

function AccountComponent({ userProfile }: AccountProps) {
  return (
    <StyledAccount>
      <Circle bgColor={"black"} color={"white"} width={"2.5rem"} size={"md"}>
        {userProfile ? (
          getInitials(userProfile.firstName, userProfile.lastName)
        ) : (
          <IconComponent width={"24px"} color={"white"} icon={ICONS.Spinner} />
        )}
      </Circle>
      <Flex dir={"column"}>
        <AccountName>
          {userProfile && userProfile.firstName + " " + userProfile.lastName}
        </AccountName>
        <AccountEmail>{userProfile && userProfile.email}</AccountEmail>
      </Flex>
    </StyledAccount>
  );
}

export default AccountComponent;
