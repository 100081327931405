import React from "react";
import {
  Container,
  Header,
  HeaderContent,
  LoginButton,
  Logo,
  Nav,
  NavBarLink,
} from "../landing.page.style";

const PageHeaderComponent: React.FC = () => {
  return (
    <Header>
      <Container>
        <HeaderContent>
          {/* Logo placeholder */}
          <Logo src="https://placehold.co/300x100/png" alt="Your Logo" />

          {/* Navigation links */}
          <Nav>
            <NavBarLink href="#hero">Home</NavBarLink>
            <NavBarLink href="#features">Features</NavBarLink>
            <NavBarLink href="#">Contact</NavBarLink>
          </Nav>

          {/* Login/Sign Up button */}
          <LoginButton>
            <a href={"/login"}>Login / Sign Up</a>
          </LoginButton>
        </HeaderContent>
      </Container>
    </Header>
  );
};

export default PageHeaderComponent;
