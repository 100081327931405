import styled from "styled-components";

export const ContextMenu = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: white;
  border-radius: 0.2rem;
  z-index: 2500;
  pointer-events: auto;
  padding-block: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.2) 0 5px 5px -3px,
    rgba(0, 0, 0, 0.14) 0 8px 10px 1px, rgba(0, 0, 0, 0.12) 0 3px 14px 2px;
`;

export const ContextMenuButton = styled.button<{
  hoverColor?: string;
}>`
  border: none;
  background-color: white;
  transition: background-color 150ms;
  padding: 0.45rem 0.8rem;
  min-width: 7rem;
  text-align: start;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 0.85rem;
  gap: 0.25rem;

  :hover {
    background-color: ${({ hoverColor }) =>
      hoverColor || "rgba(0, 0, 0, 0.12)"};
  }
`;
