import React from "react";
import {
  AppTitle,
  ButtonContainer,
  MonthYearContainer,
  RightEdgeContainer,
  TopBar,
} from "./topbar.styles";
import { useDateContext, useDateDispatch } from "../../context/date.context";
import { DATE } from "../../logic/utils/date.module";
import { usePresentationContext } from "../../context/presentation/presentation.context";
import { ModalName } from "../../models/modal.model";
import { DateActions } from "../../models/date.model";
import ProfileComponent from "./profile/profile.component";
import ViewToggleButtonComponent from "./view-toggle-button/view-toggle-button.component";
import {
  AddCircleOutline,
  ChevronLeftOutlined,
  ChevronRightOutlined,
  Today,
} from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import { IconButton } from "../../global-styles/icon-button.styles";

function TopBarComponent({
  toggleSideMenu,
}: {
  toggleSideMenu: (event: React.MouseEvent<HTMLButtonElement>) => void;
}) {
  const dispatchDate = useDateDispatch();
  const { dates } = useDateContext();
  const currDate = DATE.getValidDate(dates);
  const { toggleModalVisibility } = usePresentationContext();

  const chevronProps = {
    variant: "round",
    size: "2rem",
  } as const;

  return (
    <TopBar id={"topBar"}>
      <IconButton variant={"round"} onClick={toggleSideMenu} size={"2.5rem"}>
        <MenuIcon />
      </IconButton>
      <AppTitle>PbP</AppTitle>

      <ButtonContainer>
        <IconButton
          {...chevronProps}
          onClick={() => dispatchDate({ type: DateActions.PrevView })}
        >
          <ChevronLeftOutlined />
        </IconButton>
        <IconButton
          {...chevronProps}
          onClick={() => dispatchDate({ type: DateActions.NextView })}
        >
          <ChevronRightOutlined />
        </IconButton>
      </ButtonContainer>
      <MonthYearContainer>
        <p>{DATE.getMonthName(currDate)}</p>
        <p>{currDate.getFullYear()}</p>
      </MonthYearContainer>

      <RightEdgeContainer>
        <div style={{ display: "flex", flexShrink: 0 }}>
          <IconButton
            variant={"round"}
            size={"2.5rem"}
            onClick={() =>
              toggleModalVisibility({ type: ModalName.TASK, show: true })
            }
          >
            <AddCircleOutline />
          </IconButton>
          <IconButton
            variant={"round"}
            title={"Today"}
            size={"2.5rem"}
            onClick={() =>
              dispatchDate({
                type: DateActions.JumpToDate,
                payload: { date: new Date() },
              })
            }
          >
            <Today />
          </IconButton>
        </div>
        <ViewToggleButtonComponent />
        <ProfileComponent />
      </RightEdgeContainer>
    </TopBar>
  );
}

export default TopBarComponent;
