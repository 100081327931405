import styled from "styled-components";
import { FormButton } from "../../form/form.styles";

export const ViewToggleButton = styled(FormButton)`
  outline: none;
  border: none;
  min-width: 4.5rem;
  height: 2rem;
  border-radius: 0.2rem;
  background-color: white;
`;
