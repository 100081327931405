import React from "react";
import { IconButton } from "./icon.styles";

export interface IconComponentProps {
  width: string;
  color: string;
  icon: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

function IconComponent({ onClick, width, color, icon }: IconComponentProps) {
  const iconNode = React.createElement(icon, {
    width: width,
    height: width,
    fill: color,
  });
  return onClick ? (
    <IconButton onClick={onClick}>{iconNode}</IconButton>
  ) : (
    iconNode
  );
}

export default IconComponent;
