/**
 * getBackgroundColor: A function that dynamically calculates the background color based on the input text color and theme mode.
 *
 * @param textColor - The color of the text. Must be a valid CSS color value.
 * @param themeMode - The theme mode. Must be either "light" or "dark".
 *
 * @returns {string} - The calculated background color.
 *
 * @throws {Error} Will throw an error if the input text color is not a valid CSS color value or if the theme mode is not "light" or "dark".
 */
export function getBackgroundColor(
  textColor: string,
  themeMode: string
): string {
  // Validate the input text color
  if (!isValidColor(textColor)) {
    throw new Error(
      "Invalid text color. Please provide a valid CSS color value."
    );
  }

  // Validate the input theme mode
  if (themeMode !== "light" && themeMode !== "dark") {
    throw new Error(
      "Invalid theme mode. Please provide either 'light' or 'dark'."
    );
  }

  // Calculate the background color based on the text color and theme mode
  if (themeMode === "light") {
    // For light theme mode, use a lighter shade of the text color
    return lightenColor(textColor, 80); // NOTE: increasing the amount  lightens the color, decreasing darkens it
  } else {
    // For dark theme mode, use a darker shade of the text color
    return darkenColor(textColor, 20); // NOTE: increasing the amount  lightens the color, decreasing darkens it
  }
}

/**
 * isValidColor: A helper function to validate if a given string is a valid CSS color value.
 *
 * @param color - The color value to validate.
 *
 * @returns {boolean} - True if the color is valid, false otherwise.
 */
const isValidColor = (color: string): boolean => {
  const element = document.createElement("div");
  element.style.color = color;
  return element.style.color !== "";
};

/**
 * lightenColor: A helper function to lighten a given CSS color value.
 *
 * @param color - The color value to lighten.
 * @param amount - The amount to lighten the color by (0-100).
 *
 * @returns {string} - The lightened color.
 */
const lightenColor = (color: string, amount: number): string => {
  // Convert the color to the RGB format
  const rgbColor = hexToRgb(color);

  // Lighten the RGB color
  const lightenR = lightenChannel(rgbColor.r, amount);
  const lightenG = lightenChannel(rgbColor.g, amount);
  const lightenB = lightenChannel(rgbColor.b, amount);

  // Convert the lightened RGB color back to the hex format
  return rgbToHex(lightenR, lightenG, lightenB);
};

/**
 * darkenColor: A helper function to darken a given CSS color value.
 *
 * @param color - The color value to darken.
 * @param amount - The amount to darken the color by (0-100).
 *
 * @returns {string} - The darkened color.
 */
const darkenColor = (color: string, amount: number): string => {
  // Convert the color to the RGB format
  const rgbColor = hexToRgb(color);

  // Darken the RGB color
  const darkenR = darkenChannel(rgbColor.r, amount);
  const darkenG = darkenChannel(rgbColor.g, amount);
  const darkenB = darkenChannel(rgbColor.b, amount);

  // Convert the darkened RGB color back to the hex format
  return rgbToHex(darkenR, darkenG, darkenB);
};

/**
 * hexToRgb: A helper function to convert a hex color value to RGB format.
 *
 * @param hex - The hex color value to convert.
 *
 * @returns {object} - An object representing the RGB color values.
 */
const hexToRgb = (hex: string): { r: number; g: number; b: number } => {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const fullHexRegex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;

  hex = hex.replace(shorthandRegex, (m, r, g, b) => {
    return r + r + g + g + b + b;
  });

  const result = fullHexRegex.exec(hex);
  if (!result) {
    throw new Error(
      "Invalid hex color value. Please provide a valid hex color value."
    );
  }

  return {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  };
};

/**
 * rgbToHex: A helper function to convert RGB color values to a hex color value.
 *
 * @param r - The red channel value (0-255).
 * @param g - The green channel value (0-255).
 * @param b - The blue channel value (0-255).
 *
 * @returns {string} - The hex color value.
 */
const rgbToHex = (r: number, g: number, b: number): string => {
  return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;
};

/**
 * componentToHex: A helper function to convert a single RGB component value to a two-digit hex value.
 *
 * @param c - The RGB component value (0-255).
 *
 * @returns {string} - The two-digit hex value.
 */
const componentToHex = (c: number): string => {
  const hex = c.toString(16);
  return hex.length === 1 ? "0" + hex : hex;
};

/**
 * lightenChannel: A helper function to lighten a single RGB channel value.
 *
 * @param channel - The RGB channel value (0-255).
 * @param amount - The amount to lighten the channel by (0-100).
 *
 * @returns {number} - The lightened channel value.
 */
const lightenChannel = (channel: number, amount: number): number => {
  return Math.round(channel + (255 - channel) * (amount / 100));
};

/**
 * darkenChannel: A helper function to darken a single RGB channel value.
 *
 * @param channel - The RGB channel value (0-255).
 * @param amount - The amount to darken the channel by (0-100).
 *
 * @returns {number} - The darkened channel value.
 */
const darkenChannel = (channel: number, amount: number): number => {
  return Math.round(channel * (1 - amount / 100));
};
