import React from "react";
import { useDateContext } from "../../../context/date.context";
import { FlexContainer } from "../month-view/month-view.styles";
import {
  AllDayWeekTasksBar,
  FlexGrowScrollContainer,
  WeekDay,
  WeekDays,
} from "./week-view.styles";
import TimeBarComponent from "../shared/time-bar/time-bar.component";
import { DATE } from "../../../logic/utils/date.module";
import DynamicTaskComponent from "../shared/dynamic-task/dynamic-task.component";
import ClockBarComponent from "../shared/clock-bar/clock-bar.component";
import { useCalendarsContext } from "../../../context/calendars.context";
import useDateTasks from "../../../logic/hooks/useDateTasks";
import WeekDaysHeaderComponent from "../week-days-header/week-days-header.component";
import GridColumnComponent from "../shared/grid-column/grid-column.component";
import { ModalName } from "../../../models/modal.model";
import { usePresentationContext } from "../../../context/presentation/presentation.context";
import TaskComponent from "../shared/task/task.component";
import { TaskClass } from "../../../models/classes/task.class";

function WeekViewComponent() {
  const { dates } = useDateContext();
  const { calendars } = useCalendarsContext();
  const today = new Date();
  const { getDateTasks } = useDateTasks(3);
  const { toggleModalVisibility } = usePresentationContext();

  const calendarDayMaps = dates.map(getDateTasks);

  /**
   * Since each day in the calendar can have all day tasks, some calendar days will have reference to the same all day
   * task. And since in this case we only display each all day task once (changing the width accordingly), we need to
   * remove any duplicates and account for each all day task only once
   */
  const getUniqueAllDayTasks = () => {
    const uniqueAllDayTasks: TaskClass[] = [];
    const taskIdSet = new Set<string>();
    calendarDayMaps.forEach(({ allDayTasks }) => {
      allDayTasks.forEach((task) => {
        if (!taskIdSet.has(task.id)) {
          taskIdSet.add(task.id);
          uniqueAllDayTasks.push(task);
        }
      });
    });

    return uniqueAllDayTasks;
  };

  const handleAllDayTaskCreation = (e: React.MouseEvent) => {
    const div = e.currentTarget;
    const clickX = e.clientX - div.getBoundingClientRect().left;
    const slotWidth = div.clientWidth / DATE.DAY_NAMES.length;
    const clickedSlot = Math.floor(clickX / slotWidth);

    toggleModalVisibility({
      type: ModalName.TASK,
      show: true,
      data: {
        task: { ...DATE.getTaskDates(dates[clickedSlot], true), allDay: true },
      },
    });
  };

  return (
    <FlexContainer direction={"column"}>
      <WeekDaysHeaderComponent offsetLeft={true} dates={dates} />
      <FlexGrowScrollContainer className={"scroll"}>
        <AllDayWeekTasksBar onClick={handleAllDayTaskCreation}>
          {getUniqueAllDayTasks().map((task) => (
            <TaskComponent
              allDayInfo={{ task, dates }}
              key={task.id}
              task={task}
              calColor={calendars[task.calendarId].color}
            />
          ))}
        </AllDayWeekTasksBar>
        <div style={{ display: "flex", width: "100%" }}>
          <TimeBarComponent />
          <WeekDays>
            {calendarDayMaps.map(({ date, columnsCount, regularTasks }) => {
              return (
                <WeekDay key={date.toString()}>
                  <div>
                    {regularTasks.map((taskToShow) => (
                      <DynamicTaskComponent
                        key={taskToShow.task.id}
                        calColor={calendars[taskToShow.calendarId].color}
                        columnsCount={columnsCount}
                        {...taskToShow}
                      />
                    ))}
                  </div>
                  {DATE.areSameDay(date, today) && <ClockBarComponent />}
                  <GridColumnComponent date={date} />
                </WeekDay>
              );
            })}
          </WeekDays>
        </div>
      </FlexGrowScrollContainer>
    </FlexContainer>
  );
}

export default WeekViewComponent;
