export const VIEW_TYPES = {
  WEEK: "Week",
  MONTH: "Month",
  DAY: "Day",
  TASKS: "Tasks",

  toArray() {
    return [this.DAY, this.WEEK, this.MONTH, this.TASKS];
  },
} as const;
