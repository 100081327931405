import React, { createContext, ReactNode, useContext, useState } from "react";
import { DATE } from "../logic/utils/date.module";
import { VIEW_TYPES } from "../constants/types";
import {
  DateActions,
  DateContextState,
  DateDispatch,
  GeneralDatePayload,
} from "../models/date.model";

const DateContext = createContext<DateContextState>({
  dates: [],
  currentView: VIEW_TYPES.MONTH,
});
const DispatchDateContext = createContext<DateDispatch | null>(null);

export const useDateDispatch = () =>
  useContext(DispatchDateContext) as DateDispatch;

export const useDateContext = (): DateContextState => useContext(DateContext);

function DateContextProvider({ children }: { children: ReactNode }) {
  const [state, setState] = useState<DateContextState>({
    dates: DATE.getMonthDates(new Date()),
    currentView: VIEW_TYPES.MONTH,
  });

  const dispatch: DateDispatch = (action) => {
    switch (action.type) {
      case DateActions.NextView:
        return update({ step: 1 });
      case DateActions.PrevView:
        return update({ step: -1 });
      case DateActions.UpdateViewType:
      case DateActions.JumpToDate:
        return update(action.payload);
      case DateActions.UpdateTasksRange:
        return setDatesByRange(
          action.payload.startDate,
          action.payload.endDate
        );
      default:
        break;
    }
  };

  const setDatesByRange = (startDate: Date, endDate: Date) => {
    setState({ ...state, dates: DATE.getDatesInRange(startDate, endDate) });
  };

  const update = ({
    currentView = state.currentView,
    date,
    step = 0,
  }: GeneralDatePayload) => {
    if (!date) {
      // if no custom date is provided, look for today in the current view and go to it
      // if today is not part of the current view use the dates in the current view
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      const isTodayInRange = state.dates.some((date) =>
        DATE.areSameDay(date, today)
      );
      date =
        isTodayInRange && currentView !== VIEW_TYPES.MONTH
          ? today
          : DATE.getValidDate(state.dates);
    }

    switch (currentView) {
      case VIEW_TYPES.DAY:
        date.setDate(date.getDate() + step);
        return setState({
          currentView,
          dates: [date],
        });
      case VIEW_TYPES.WEEK:
        date.setDate(date.getDate() + 7 * step);
        return setState({
          currentView,
          dates: DATE.getWeekDates(date),
        });
      case VIEW_TYPES.MONTH:
        date.setMonth(date.getMonth() + step);
        return setState({
          currentView,
          dates: DATE.getMonthDates(date),
        });
      case VIEW_TYPES.TASKS:
        return setState({
          dates:
            state.currentView === currentView
              ? // if we go from a TASK_VIEW to TASK_VIEW that means the TODAY button was clicked
                DATE.getWeekDates(date)
              : // if we go from any view to TASK_VIEW, we use the current dates
                state.dates.map((date) => date),
          currentView,
        });
      default:
        break;
    }
  };

  return (
    <DateContext.Provider value={state}>
      <DispatchDateContext.Provider value={dispatch}>
        {children}
      </DispatchDateContext.Provider>
    </DateContext.Provider>
  );
}

export default DateContextProvider;
