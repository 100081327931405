import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthActionContext } from "../../context/auth.context";
import FormComponent, { FormState } from "../../components/form/form.component";
import { FormWrapper } from "../../components/form/form.styles";

function LoginPage() {
  const { logIn } = useAuthActionContext();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const handleSubmit = async ({ email, password }: FormState) => {
    try {
      const result = await logIn(email, password);
      console.log(result);
      navigate("/");
    } catch (e: any) {
      console.dir(e);
      setError(
        e.code === "auth/wrong-password"
          ? "Incorrect email or password!"
          : e.message
      );
      throw e;
    }
  };

  return (
    <FormWrapper direction={"column"}>
      <FormComponent
        buttons={[{ text: "Log In", type: "submit", bgColor: "darkblue" }]}
        title={"Log in"}
        link={{ text: "Create an account now", path: "/signup" }}
        fields={[
          {
            name: "email",
            info: {
              type: "email",
            },
            placeholder: "Email",
            label: "Enter your email",
          },
          {
            name: "password",
            info: {
              type: "password",
            },
            placeholder: "Password",
            label: "Enter your password",
          },
        ]}
        error={error}
        onSubmit={handleSubmit}
      />
    </FormWrapper>
  );
}

export default LoginPage;
