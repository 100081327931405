import React from "react";
import {
  CtaButton,
  HeroContent,
  HeroDescription,
  HeroSection,
} from "../landing.page.style";
// @ts-ignore
import { ReactComponent as TaskSVG } from "../img/task.svg";

const PageHeroComponent: React.FC = () => {
  return (
    <HeroSection id={"hero"}>
      {/* Hero content */}
      <HeroContent>
        <HeroDescription>
          <h1>Seamlessly Manage Your Schedule and Boost Productivity</h1>
          <p>
            Pinboard Planner is the ultimate calendar app integrated with
            powerful productivity features.
          </p>
          <CtaButton>
            <a href={"/signup"}>Get Started</a>
          </CtaButton>
        </HeroDescription>
        <TaskSVG height={"25rem"} />
      </HeroContent>
    </HeroSection>
  );
};

export default PageHeroComponent;
