import styled from "styled-components";
import theme from "../../../constants/theme/theme";

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const TasksPageHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 20rem;
`;

export const ChartContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 14rem;
  font-weight: bold;
`;

export const ChartOptions = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: chocolate; // ---------------------- REMOVE LATER
`;

export const PieChart = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00bcd4; // ---------------------- REMOVE LATER
`;

export const DateSelector = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 6rem;
  justify-content: center;
  align-items: center;
  background: burlywood; // ---------------------- REMOVE LATER
`;

export const DateContainer = styled.div`
  display: flex;
  width: fit-content;
  height: 3rem;
`;

export const DateInput = styled.input`
  width: ${theme.spacing
    .tvButtonWidth}; // -------------------------- CHANGE AFTER MAKING DROP DOWN
  height: ${theme.spacing
    .tvButtonHeight}; // -------------------------- CHANGE AFTER MAKING DROP DOWN
  font-size: ${theme.font.tvButtonSize};
  align-items: center;
  text-align: center;
  margin: ${theme.spacing.tvMargin};
  border-radius: ${theme.spacing.tvButtonBorderRadius};
`;

export const SubmitButton = styled.button`
  width: ${theme.spacing
    .tbButtonWidth}; // -------------------------- CHANGE AFTER MAKING DROP DOWN
  height: ${theme.spacing
    .tbButtonHeight}; // -------------------------- CHANGE AFTER MAKING DROP DOWN
  font-size: ${theme.font.tvButtonSize};
  align-items: center;
  text-align: center;
  margin: ${theme.spacing.tvMargin};
  border-radius: ${theme.spacing.tvButtonBorderRadius};
`;

export const TasksPageBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  overflow-y: scroll;
  gap: 1rem;
  padding-block: 1rem;

  ::-webkit-scrollbar {
    width: 0;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #000000;
    width: 2rem;
  }
`;

export const TasksByDateSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: fit-content;
  overflow: unset;
  width: 80%;
  align-items: center;
  padding-bottom: 2rem;
  border-bottom: 1px solid grey;

  h2 {
    font-size: 1rem;
    color: grey;
  }

  :last-child {
    border-bottom: none;
  }
`;

// TODO: Remove hardcoing and replace with theme
export const TaskContent = styled.div`
  background: cadetblue; // ---------------------- REMOVE LATER
  border: 2px solid black;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: ${theme.spacing.tvButtonBorderRadius};
  min-height: 3rem;
  margin: 0.5rem;
  flex-wrap: wrap;
`;

export const TaskInfoWrapper = styled.div`
  width: calc(100% - 4rem);
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    cursor: pointer;

    & > svg {
      visibility: visible;
    }
  }
`;

export const TaskInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const TaskCheckbox = styled.input`
  width: 1.5rem;
  aspect-ratio: 1;
  margin: 0.5rem 1rem;
`;

export const TaskTitle = styled.h3`
  line-height: 3rem;
  margin-inline: 1rem;
`;
