import React from "react";
import { Circle } from "../../../styles.global";
import { useAuth, useAuthActionContext } from "../../../context/auth.context";
import { usePresentationContext } from "../../../context/presentation/presentation.context";
import { Flex } from "../../modal/modal-shared.styles";
import { FormButton } from "../../form/form.styles";
import {
  AccountEmail,
  AccountName,
} from "../../modal/share-calendar-modal/account/account.styles";

function ProfileComponent() {
  const currentUser = useAuth();
  const { logOut } = useAuthActionContext();
  const { displayContextMenu } = usePresentationContext();

  const openProfileMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    const profileContent = (
      <Flex
        dir={"column"}
        alignItems={"center"}
        gap={"0.25rem"}
        style={{ height: "100%", width: "100%", padding: "1rem" }}
      >
        <AccountName>{currentUser?.displayName}</AccountName>
        <AccountEmail>{currentUser?.email}</AccountEmail>
        <FormButton
          type={"button"}
          style={{ marginTop: "1rem" }}
          onClick={logOut}
        >
          Log out
        </FormButton>
      </Flex>
    );
    displayContextMenu({
      event,
      alignToEdge: true,
      menu: [],
      children: profileContent,
      marginTop: 10,
      height: "auto",
      width: "15rem",
    });
  };
  return (
    <Circle
      bgColor={"black"}
      color={"white"}
      as={"button"}
      onClick={openProfileMenu}
      style={{ width: "2.5rem", padding: 0 }}
    >
      <span>
        {currentUser?.displayName
          ?.split(" ")
          .map((word) => word.charAt(0).toUpperCase())
          .join("")}
      </span>
    </Circle>
  );
}

export default ProfileComponent;
