import React, { useEffect, useRef } from "react";
import useCurrentDateTime from "../../../../logic/hooks/useCurrentDateTime";
import { ClockBar } from "./clock-bar.styles";
import { useDateContext } from "../../../../context/date.context";

function ClockBarComponent() {
  const [currentDateTime] = useCurrentDateTime();
  const clockRef = useRef<HTMLDivElement | null>(null);
  const { dates, currentView } = useDateContext();

  useEffect(() => {
    clockRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
  }, [dates, currentView]);

  return <ClockBar date={currentDateTime} ref={clockRef} />;
}

export default ClockBarComponent;
