import React from "react";
import MonthViewComponent from "./month-view/month-view.component";
import { useDateContext } from "../../context/date.context";
import WeekViewComponent from "./week-view/week-view.component";
import DayViewComponent from "./day-view/day-view.component";
import { VIEW_TYPES } from "../../constants/types";
import { View } from "./view.styles";
import TaskViewComponent from "./task-view/task-view.component";

function ViewComponent() {
  const { currentView } = useDateContext();

  return (
    <View>
      {{
        [VIEW_TYPES.DAY]: <DayViewComponent />,
        [VIEW_TYPES.WEEK]: <WeekViewComponent />,
        [VIEW_TYPES.MONTH]: <MonthViewComponent />,
        [VIEW_TYPES.TASKS]: <TaskViewComponent />,
      }[currentView] || <div>Error: Invalid view.</div>}
    </View>
  );
}

export default ViewComponent;
