import React, { useEffect } from "react";
import FormComponent, { FormField } from "../../form/form.component";
import { RoleType } from "../../../models/calendar";
import { useCalendarsContext } from "../../../context/calendars.context";
import { updateCalendarPermissions } from "../../../logic/firebase/db-service/calendars/calendars.db";
import { useUsersContext } from "../../../context/users.context";
import AccountComponent from "./account/account.component";
import { RoleGroup, RoleGroupHeader } from "./account/account.styles";
import { Flex } from "../modal-shared.styles";

export interface ShareCalendarProps {
  calendarId: string;
}

const FIELDS: FormField[] = [
  {
    name: "email",
    info: { type: "email" },
    label: "Enter email to share calendar with:",
  },
  {
    name: "permission",
    info: {
      type: "select",
      options: [RoleType.Editor, RoleType.Viewer].map((role) => ({
        text: role,
      })),
    },
  },
];

function ShareCalendarModalComponent({ calendarId }: ShareCalendarProps) {
  const { calendars } = useCalendarsContext();
  const { name, editors, viewers, ownerId } = calendars[calendarId];
  const { fetchUsers, users } = useUsersContext();

  useEffect(() => {
    const idsToFetch: string[] = [ownerId];
    if (editors) idsToFetch.push(...editors);
    if (viewers) idsToFetch.push(...viewers);
    fetchUsers(idsToFetch).then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewers, editors]);

  const renderUsers = (userIds: string[] | undefined, roleType: RoleType) => {
    if (!userIds) return null;
    return (
      <RoleGroup>
        <RoleGroupHeader>
          {roleType}
          {roleType === RoleType.Owner ? "" : "s"}
        </RoleGroupHeader>
        <Flex
          dir={"column"}
          gap={"1rem"}
          style={{ maxHeight: "6rem", overflowY: "auto" }}
        >
          {userIds.map((uid) => (
            <AccountComponent key={uid} userProfile={users[uid]} />
          ))}
        </Flex>
      </RoleGroup>
    );
  };
  return (
    <FormComponent
      resetOnSuccess={true}
      buttons={[{ text: "Update", type: "submit", bgColor: "darkblue" }]}
      title={`Manage '${name}' permissions`}
      onSubmit={async (formData) => {
        if ("email" in formData && "permission" in formData) {
          await updateCalendarPermissions(
            formData.email,
            formData.permission as RoleType,
            calendarId
          );
        } else {
          alert("Data invalid");
        }
      }}
      fields={FIELDS}
    >
      {renderUsers([ownerId], RoleType.Owner)}
      {renderUsers(viewers, RoleType.Viewer)}
      {renderUsers(editors, RoleType.Editor)}
    </FormComponent>
  );
}

export default ShareCalendarModalComponent;
