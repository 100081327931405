import styled from "styled-components";
import theme, { WEEK_HEADER_HEIGHT } from "../../../constants/theme/theme";

export const MonthDay = styled.div<{
  length: number;
  index: number;
}>`
  border-right: 1px solid ${theme.colors.gridBorders};
  border-bottom: 1px solid ${theme.colors.gridBorders};
  width: calc(100% / 7);
  padding: 0.2rem;
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
  height: ${({ length }) => `calc(100% / ${length / 7} )`};
  overflow: hidden;
`;

export const FlexGrowContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: calc(100% - ${WEEK_HEADER_HEIGHT});
`;

export const FlexContainer = styled.div<{
  direction: "column" | "row";
}>`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  height: 100%;
`;

export const MonthDayDate = styled.h4<{
  highlight: boolean;
}>`
  border-radius: 50%;
  background-color: ${({ highlight }) => highlight && theme.colors.currentDay};
  color: ${({ highlight }) => highlight && "white"};
  width: fit-content;
  aspect-ratio: 1;
  display: grid;
  place-items: center;
  font-weight: 500;
  font-size: 0.8rem;
  min-height: 1.3rem;
`;

export const MonthTasksList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
`;
