import styled from "styled-components";
import { SIDE_MENU_WIDTH } from "../side-menu.styles";
import { IconButton } from "../../../global-styles/icon-button.styles";

const PADDING = "0.5rem";

export const CalendarGroupWrapper = styled.div`
  width: ${SIDE_MENU_WIDTH};
  position: relative;
  flex: 1;
  --header-height: 2.5rem;
`;
export const CalendarGroupHeader = styled.header`
  display: flex;
  align-items: center;
  padding: ${PADDING};
  font-size: 0.8rem;
  height: var(--header-height);
  color: grey;
`;

export const AddCalendarButton = styled(IconButton)`
  margin-left: auto;
  aspect-ratio: 1;
  width: 1.5rem;
  border-radius: 50%;
  border: none;
  background: lightgrey;
  cursor: pointer;
`;

export const CalendarList = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  position: absolute;
  width: 100%;
  overflow-y: auto;
  height: calc(100% - var(--header-height));
  max-height: 10rem;
  padding-inline: ${PADDING};
`;
