import styled from "styled-components";
import theme, {
  TIME_BAR_WIDTH,
  WEEK_HEADER_HEIGHT,
} from "../../../constants/theme/theme";

export const WeekHeader = styled.div<{ offsetLeft: boolean }>`
  display: flex;
  height: ${WEEK_HEADER_HEIGHT};
  background-color: ${({ theme }) => theme.colors.headers};
  padding-left: ${({ offsetLeft }) => offsetLeft && TIME_BAR_WIDTH};
`;
export const WeekDayHeading = styled.div`
  width: calc(100% / 7);
  font-weight: 500;
  padding-block: 0.2rem;
  border-right: 1px solid grey;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const WeekDayName = styled.div`
  text-align: center;
  text-transform: capitalize;
`;

export const WeekDayDate = styled.div<{ highlight: boolean }>`
  text-align: center;
  width: fit-content;
  aspect-ratio: 1;
  padding: 0.3rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  background-color: ${({ highlight }) => highlight && theme.colors.currentDay};
  color: ${({ highlight }) => highlight && theme.colors.currentDayText};
`;
