import React from "react";
import { GridSlot } from "../../week-view/week-view.styles";
import { usePresentationContext } from "../../../../context/presentation/presentation.context";
import { ModalName } from "../../../../models/modal.model";
import { DATE } from "../../../../logic/utils/date.module";

const GridColumnComponent = ({ date }: { date: Date }) => {
  const { toggleModalVisibility } = usePresentationContext();
  return (
    <div
      onClick={(event: React.MouseEvent) => {
        const div = event.currentTarget;
        const clickY = event.clientY - div.getBoundingClientRect().top;
        const slotHeight = div.clientHeight / DATE.TIME_SLOTS.length;
        const clickedSlot = Math.floor(clickY / slotHeight);

        // Determine if the click was in the top or bottom half of the slot
        const isTopHalf = clickY % slotHeight < slotHeight / 2;

        // Create a Date object based on the clicked slot and whether it's in the top or bottom half
        const taskStartDateTime = new Date(date);
        taskStartDateTime.setHours(clickedSlot);
        taskStartDateTime.setMinutes(isTopHalf ? 0 : 30);
        taskStartDateTime.setSeconds(0);

        toggleModalVisibility({
          type: ModalName.TASK,
          show: true,
          data: { task: DATE.getTaskDates(taskStartDateTime) },
        });
      }}
    >
      {DATE.TIME_SLOTS.map((ts, i) => (
        <GridSlot key={`${ts}${i}`} />
      ))}
    </div>
  );
};

export default GridColumnComponent;
