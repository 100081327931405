import styled from "styled-components";
import { FlexContainer } from "../view/month-view/month-view.styles";

export const FormWrapper = styled(FlexContainer)`
  background-color: #855656;
`;

export const Form = styled.form`
  width: 25rem;
  padding: 2rem;
  margin: auto;
  background-color: #efedff;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

export const FormButtonGroup = styled.div`
  display: flex;
  gap: 1rem;
`;

export const FormButton = styled.button<{
  bgColor?: string;
  color?: string;
  type: "submit" | "button";
}>`
  padding: 0.4rem 1rem;
  transition: filter 200ms;
  background-color: ${({ bgColor }) => bgColor || "#2c3588"};
  color: ${({ color }) => color || "white"};
  min-width: 6rem;
  min-height: 2.2rem;
  border-radius: 0.25rem;
  border: none;

  :hover:not(:disabled) {
    filter: brightness(80%);
    cursor: pointer;
  }

  :disabled {
    background-color: grey;
  }
`;

export const FormTitle = styled.h2`
  font-size: 1rem;
`;

export const FormItem = styled.div<{ direction?: "row" | "column" }>`
  display: flex;
  flex-direction: ${({ direction }) => direction || "column"};
  align-items: ${({ direction }) => direction === "row" && "center"};
  width: 75%;
  gap: 0.2rem;
`;

export const FormError = styled.div`
  font-size: 0.8rem;
  font-weight: 500;
  color: red;
`;

export const Label = styled.label`
  color: grey;
  font-size: 0.8rem;
`;

export const Input = styled.input<{ as?: string }>`
  border: 1px solid #a9a9a9;
  font-family: inherit;
  padding: ${({ type }) => type !== "color" && "0.5rem"};
  border-radius: ${({ theme }) => theme.spacing.borderRadius || "0.25rem"};
  outline: none;
  transition: border 150ms;
  max-height: 200px;
  resize: vertical;

  :focus {
    border: 1px solid #7a97d9;
  }
`;

export const Select = styled(Input).attrs(() => ({
  as: "select",
}))``;

export const TextArea = styled(Input).attrs(() => ({
  as: "textarea",
}))``;
