import styled from "styled-components";

export const CalendarsGroupItem = styled.div`
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-shrink: 0;
  border: 1px solid grey;
  overflow: hidden;
`;

export const CalendarItemColor = styled.div<{ bgColor: string }>`
  background-color: ${({ bgColor }) => bgColor};
  width: 1.5rem;
  padding-block: 0.5rem;
  display: flex;
  align-self: stretch;
  justify-content: center;
  align-items: center;
`;
