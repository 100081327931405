import React from "react";
import SideMenuComponent from "../side-menu/side-menu.component";
import ViewComponent from "../view/view.component";
import { TOP_BAR_HEIGHT } from "../../constants/theme/theme";
import styled from "styled-components";

export const StyledContentContainer = styled.div`
  display: flex;
  position: relative;
  height: calc(100% - ${TOP_BAR_HEIGHT});
`;

function ContentComponent({
  isSideMenuOpen,
  closeSideMenu,
}: {
  isSideMenuOpen: boolean;
  closeSideMenu: () => void;
}) {
  return (
    <StyledContentContainer>
      <SideMenuComponent
        isOpen={isSideMenuOpen}
        closeSideMenu={closeSideMenu}
      />
      <ViewComponent />
    </StyledContentContainer>
  );
}

export default ContentComponent;
