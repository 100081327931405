import { RepeatType } from "../components/modal/task-modal/task-repeat/task-repeat.types";
import { TaskClass } from "./classes/task.class";

export enum TaskType {
  REMINDER = "Reminder",
  EVENT = "Event",
  TASK = "Task",
}

export interface TaskDB {
  description: string;
  startDate: Date;
  endDate: Date;
  color: string;
  isComplete: boolean;
  meetingLink: string;
  title: string;
  type: TaskType;
  allDay: boolean;
  repeatInfo?: RepeatInfo | null;
}

export interface RepeatWeekDays {
  su?: boolean;
  mo?: boolean;
  tu?: boolean;
  we?: boolean;
  th?: boolean;
  fr?: boolean;
  sa?: boolean;
}

export interface RepeatInfo {
  // when a task is repeating, we use instanceEndDate because the endDate refers
  // to the date in which the task stops repeating rather than the actual duration of the task
  instanceEndDate: Date;
  repeatEvery: number;
  repeatType:
    | {
        type: Exclude<RepeatType, "week">;
      }
    | {
        type: "week";
        weekDays: RepeatWeekDays;
      };
}

export interface Task extends TaskDB {
  calendarId: string;
  id: string;
}

export interface TaskUpdatePayload {
  id: string;
  calendarId: string;
  fieldsToUpdate: Partial<TaskDB>;
}

export enum TaskActions {
  Add = "Add",
  Delete = "Delete",
  Edit = "Edit",
  SetAll = "SetAll",
  SetIsComplete = "SetIsComplete",
}

export type TasksByCalendarMap = { [key: string]: TaskClass[] };

export type TaskAction = {
  type: TaskActions;
  payload: { tasks: TaskClass[]; calendarId: string };
};
