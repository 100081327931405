import React, { useState } from "react";
import { FormInputType } from "../../components/form/form-input/form-input.component";
import { DATE } from "../utils/date.module";
import {
  ChangeEvent,
  FieldOptions,
  FormFieldValue,
  FormRegistration,
  UseFormReturn,
  ValidationSchema,
} from "./models/use-form.types";

const useForm = <T>(
  initialValues: T,
  validationSchema: ValidationSchema<T>
): UseFormReturn<T> => {
  const [state, _setState] = useState(initialValues);
  const [errors, _setErrors] = useState<Record<keyof T, string>>();

  const handleChange = (
    e: ChangeEvent,
    forcedValue: string | undefined,
    options?: FieldOptions<T>
  ) => {
    const value = forcedValue ?? e.target.value;
    const name = e.target.name as keyof T;
    let finalValue: FormFieldValue = value;
    // handle checkbox, number, and date picker cases
    if (e.target instanceof HTMLInputElement) {
      const { checked, type } = e.target;
      switch (type as FormInputType) {
        case "checkbox":
          finalValue = checked;
          break;
        case "datetime-local":
        case "date":
          // if field is required, prevent clearing the value
          if (validationSchema[name].required && !value) {
            return;
          } else {
            finalValue = value ? new Date(value) : "";
          }
          break;
        case "number":
          // prevent negative numbers
          if (value.includes("-")) return;
          finalValue = e.target.valueAsNumber;
          if (isNaN(finalValue)) finalValue = "";
          break;
      }
    }
    const stateUpdate = {
      [name]: finalValue,
      // if the change of this field should change another field, then we do that here
      ...(options?.sideEffect
        ? { [options.sideEffect.name]: options.sideEffect.value }
        : {}),
    };
    _setState({ ...state, ...stateUpdate });

    const errorMsg = getErrorMsg(name, finalValue);
    setError(name, errorMsg);
    if (options?.afterChangeCallback) options?.afterChangeCallback();
  };

  const register = (
    name: keyof T,
    type: FormInputType,
    options?: FieldOptions<T>
  ): FormRegistration => {
    let htmlValue: FormFieldValue;
    switch (type) {
      case "datetime-local":
        htmlValue = DATE.toHTML_Datetime(state[name] as Date);
        break;
      case "date":
        htmlValue = DATE.toHtmlInput(state[name] as Date);
        break;
      default:
        htmlValue = state[name] as string | boolean;
        break;
    }

    return {
      value: htmlValue,
      type,
      name: name as string,
      onChange: (e: ChangeEvent, forcedValue?: string) =>
        handleChange(e, forcedValue, options),
      error: errors ? errors[name] : "",
    };
  };

  const getErrorMsg = (name: keyof T, value: FormFieldValue) => {
    const { required = false, greaterThan } = validationSchema[name];
    if (required && !Boolean(value)) {
      return "Field is required";
    }

    if (greaterThan) {
      const currentValue = value;
      const comparedValue = state[greaterThan];
      if (
        comparedValue instanceof Date &&
        currentValue instanceof Date &&
        currentValue.getTime() <= comparedValue.getTime()
      ) {
        return `Date must be after ${DATE.toPrettyDateTime(comparedValue)}`;
      } else if (
        typeof currentValue === "number" &&
        typeof comparedValue === "number" &&
        currentValue <= comparedValue
      ) {
        return `Value must be higher than ${comparedValue}`;
      }
    }

    return "";
  };

  const _updateAllFieldErrors = (): boolean => {
    let anyPresentError = false;
    const errors = (
      Object.keys(state as unknown as object) as (keyof T)[]
    ).reduce((acc, key) => {
      const value = state[key];
      const errorMsg = getErrorMsg(key, value as unknown as FormFieldValue);
      if (errorMsg) anyPresentError = true;
      acc[key] = errorMsg;
      return acc;
    }, {} as Record<keyof T, string>);
    _setErrors(errors);
    return anyPresentError;
  };

  const carefullyUpdateVal = (name: keyof T, value: FormFieldValue) => {
    _setState({ ...state, [name]: value });
  };

  const setError = (name: keyof T, value: string) => {
    _setErrors({ ...errors, [name]: value } as Record<keyof T, string>);
  };

  const handleSubmit =
    (callback?: (formData: T) => Promise<boolean>): React.FormEventHandler =>
    (e) => {
      e.preventDefault();
      if (_updateAllFieldErrors() || !callback) return;
      callback(state as T)
        .then((shouldReset) => {
          shouldReset && _setState(initialValues);
        })
        .catch((e) => {
          // TODO: handle errors here
          console.log(e);
        });
    };

  return {
    formData: state,
    handleSubmit,
    register,
    carefullyUpdateVal,
  } as const;
};

export default useForm;
