import React, { useEffect, useState } from "react";
import { DATE } from "../../../logic/utils/date.module";
import { useDateContext, useDateDispatch } from "../../../context/date.context";
import {
  MiniButtonContainer,
  MiniCalendar,
  MiniCalendarTitle,
  MiniDate,
  MiniDateList,
  MiniDay,
  MiniDayList,
  MiniMonthYearName,
} from "./mini-calendar.styles";
import { VIEW_TYPES } from "../../../constants/types";
import { DateActions } from "../../../models/date.model";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { IconButton } from "../../../global-styles/icon-button.styles";

function MiniCalendarComponent() {
  const [dates, setDates] = useState(DATE.get42Calendar(new Date()));
  const { currentView, dates: globalDates } = useDateContext();
  const dispatchDate = useDateDispatch();

  const currentDate = DATE.getValidDate(dates);

  const nextMonth = (step: number) => {
    const nextDate = new Date(DATE.getValidDate(dates));
    nextDate.setMonth(nextDate.getMonth() + step);
    setDates(DATE.get42Calendar(nextDate));
  };

  const WEEK_DAY_NAMES = ["SU", "M", "TU", "W", "TH", "F", "SA"];

  useEffect(() => {
    setDates(DATE.get42Calendar(DATE.getValidDate(globalDates)));
  }, [globalDates]);

  return (
    <MiniCalendar id={"mini-calendar"}>
      <MiniCalendarTitle>
        <MiniMonthYearName ml={"0.85rem"}>
          {DATE.getMonthName(currentDate)}
        </MiniMonthYearName>
        <MiniMonthYearName ml={"0.5rem"}>
          {currentDate.getFullYear()}
        </MiniMonthYearName>
        <MiniButtonContainer>
          <IconButton
            variant={"round"}
            size={"1.5rem"}
            onClick={() => nextMonth(-1)}
          >
            <ChevronLeft fontSize={"small"} />
          </IconButton>
          <IconButton
            variant={"round"}
            size={"1.5rem"}
            onClick={() => nextMonth(+1)}
          >
            <ChevronRight fontSize={"small"} />
          </IconButton>
        </MiniButtonContainer>
      </MiniCalendarTitle>
      <MiniDayList>
        {WEEK_DAY_NAMES.map((day) => (
          <MiniDay key={day}>{day}</MiniDay>
        ))}
      </MiniDayList>
      <MiniDateList id={"list"}>
        {dates.map((date) => (
          <MiniDate
            key={date.toString()}
            date={date}
            currentView={currentView}
            globalDates={globalDates}
            onClick={() =>
              dispatchDate({
                type: DateActions.JumpToDate,
                payload: { currentView: VIEW_TYPES.DAY, date },
              })
            }
          >
            {date.getDate()}
          </MiniDate>
        ))}
      </MiniDateList>
    </MiniCalendar>
  );
}

export default MiniCalendarComponent;
