import React from "react";
import { ModalName } from "../../../../models/modal.model";
import {
  MonthTaskTitle,
  StyledTask,
  TaskContentWrapper,
  TaskTimestamp,
} from "./task.styles";
import { DATE } from "../../../../logic/utils/date.module";
import { Task } from "../../../../models/task.types";
import { usePresentationContext } from "../../../../context/presentation/presentation.context";
import { updateTaskDB } from "../../../../logic/firebase/db-service/calendars/tasks/tasks.db";
import { useAuth } from "../../../../context/auth.context";
import { TaskClass } from "../../../../models/classes/task.class";

export interface AllDayInfo {
  task: Task;
  dates: Date[];
}

interface MonthTaskProps {
  task: TaskClass;
  calColor: string;
  allDayInfo?: AllDayInfo;
  showCheckBox?: boolean;
  size?: "lg" | "sm";
}

function TaskComponent({
  task,
  calColor,
  allDayInfo,
  showCheckBox,
  size,
}: MonthTaskProps) {
  const { toggleModalVisibility } = usePresentationContext();
  const currentUser = useAuth();

  const handleTaskCompletionToggle = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    try {
      e.stopPropagation();
      if (!currentUser) throw Error("Must be authenticated to edit tasks");
      await updateTaskDB(currentUser?.uid, {
        id: task.id,
        calendarId: task.calendarId,
        fieldsToUpdate: { isComplete: e.target.checked },
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <StyledTask
      allDayInfo={allDayInfo}
      size={size}
      calColor={calColor}
      key={task.id}
      type={task.type}
      isComplete={task.isComplete}
      onClick={(e) => {
        e.stopPropagation();
        toggleModalVisibility({
          type: ModalName.TASK,
          show: true,
          data: { task, isEditing: true },
        });
      }}
    >
      <TaskContentWrapper>
        {showCheckBox && (
          <input
            type={"checkbox"}
            style={{ width: "1rem", flexShrink: 0 }}
            checked={task.isComplete}
            onChange={handleTaskCompletionToggle}
            onClick={(e) => e.stopPropagation()}
          />
        )}

        <TaskTimestamp>{DATE.toTimestamp(task.startDate)}</TaskTimestamp>
        <MonthTaskTitle>{task.title}</MonthTaskTitle>
      </TaskContentWrapper>
      {/*  TODO: Add edit icon back here */}
    </StyledTask>
  );
}

export default TaskComponent;
