import styled, { css } from "styled-components";
import { FormInputStyledProps, FormInputType } from "./form-input.component";
import { StyleSize } from "../../../models/styles.types";

const ERROR_COLOR = "red";
export const FOCUS_COLOR = "#888888";
export const BORDER_COLOR = "lightgrey";
export const FormInputWrapper = styled.div<
  {
    error: boolean;
    type: FormInputType;
  } & StyleSize &
    FormInputStyledProps
>`
  display: flex;
  flex-direction: ${({ dir = "col" }) => (dir === "row" ? "row" : "column")};
  align-items: ${({ dir = "col" }) => (dir === "col" ? "" : "center")};
  gap: 0.25rem;

  label {
    font-size: 0.7rem;
    color: grey;
    transform: ${({ dir = "col", hideError }) =>
      dir === "row" && !hideError && "translateY(-50%)"};
  }

  input,
  textarea {
    padding: ${({ type }) => type !== "color" && "0.5rem"};
    font-size: 0.8rem;
    border-radius: 0.25rem;
    border: 1px solid ${BORDER_COLOR};
    outline-offset: -2px;
    width: ${({ size }) =>
      ({ sm: "3rem", md: "4rem", lg: "5rem", unset: "" }[size || "unset"])};

    :focus {
      outline: 2px solid ${FOCUS_COLOR};
    }
  }

  input:required {
    border: ${({ error }) => error && `1px solid ${ERROR_COLOR}`};

    :focus {
      outline: ${({ error }) => error && `2px solid ${ERROR_COLOR}`};
    }
  }

  input[type="color"] {
    height: 33px;
    width: 4rem;
  }

  textarea {
    min-width: 100%;
    max-height: 10rem;
    min-height: 5rem;
    resize: vertical;
  }

  ${({ type }) =>
    type === "checkbox" &&
    css`
      padding-block: 0.5rem;
      align-items: center;

      label {
        font-size: 0.8rem;
      }
    `};
`;

export const FieldError = styled.p`
  color: ${ERROR_COLOR};
  font-size: 0.6rem;

  :empty::before {
    content: "";
    display: inline-block;
  }
`;
