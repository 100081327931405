import React, { createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
} from "firebase/auth";
import { auth, db } from "../logic/firebase/firebase";
import { doc, setDoc } from "firebase/firestore";
import { UserPayload } from "../models/user.model";
import { User } from "@firebase/auth";
import { UserCredential } from "firebase/auth/dist/auth";

export interface AuthActions {
  signUp: (user: UserPayload) => Promise<UserCredential>;
  logIn: (email: string, password: string) => Promise<UserCredential>;
  logOut: () => Promise<void>;
}

const AuthContext = createContext<User | null>(null);
const AuthActionContext = createContext<AuthActions | null>(null);
export const useAuth = () => useContext(AuthContext);
export const useAuthActionContext = () => useContext(AuthActionContext)!;

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const signUp = async ({
    email,
    password,
    firstName,
    lastName,
  }: UserPayload): Promise<UserCredential> => {
    try {
      const userAuth = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      await updateProfile(userAuth.user, {
        displayName: `${firstName} ${lastName}`,
      });

      const { uid } = userAuth.user;
      await setDoc(doc(db, "users", uid), {
        firstName: firstName,
        lastName: lastName,
        email: email,
        uid: uid,
      });
      return userAuth;
    } catch (e) {
      throw e;
    }
  };

  const logIn = (email: string, password: string): Promise<UserCredential> =>
    signInWithEmailAndPassword(auth, email, password);

  const logOut = () => signOut(auth);

  useEffect(() => {
    return onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setIsLoading(false);
    });
  }, []);

  return (
    <AuthActionContext.Provider
      value={{
        signUp,
        logIn,
        logOut,
      }}
    >
      <AuthContext.Provider value={currentUser}>
        {!isLoading && children}
      </AuthContext.Provider>
    </AuthActionContext.Provider>
  );
};
