import React from "react";
import { ColorOption, GridColorPicker } from "./grid-color-picker.styles";
import FormCheckboxComponent from "../form/form-checkbox/form-checkbox.component";
import { FormRegistration } from "../../logic/hooks/models/use-form.types";

export const theme: string = "light";

export const COLOR_PICKER_PALETTE = [
  { name: "Red", dark: "#EB352A", light: "#D82D35" },
  { name: "Green", dark: "#3B8E49", light: "#1A6333" },
  { name: "Blue", dark: "#2B5CC3", light: "#1D408E" },
  { name: "Yellow", dark: "#EED94E", light: "#A78B2C" },
  { name: "Orange", dark: "#FF8C00", light: "#FFA500" },
  { name: "Purple", dark: "#8B008B", light: "#9400D3" },
  { name: "Pink", dark: "#FF69B4", light: "#FFB6C1" },
  { name: "Mint", dark: "#66CC99", light: "#66FFCC" },
  { name: "Coral", dark: "#FF6B6B", light: "#FF8C8C" },
  { name: "Slate Blue", dark: "#6A5ACD", light: "#7B68EE" },
  { name: "Olive", dark: "#808000", light: "#556B2F" },
  { name: "Teal", dark: "#008080", light: "#008B8B" },
  { name: "Cyan", dark: "#00CED1", light: "#20B2AA" },
  { name: "Lavender", dark: "#967BB6", light: "#B39EB5" },
  { name: "Gold", dark: "#FFD700", light: "#FFD700" },
];

interface ColorPickerOption {
  name: string;
  code: string;
}

function GridColorPickerComponent({
  registration: { name, onChange, value, error },
  colors,
  label,
}: {
  registration: FormRegistration;
  colors?: readonly ColorPickerOption[];
  label?: string;
}) {
  const colorsArray = () => {
    return COLOR_PICKER_PALETTE.map((colorObject) => ({
      name: colorObject.name,
      code: theme === "dark" ? colorObject.dark : colorObject.light,
    }));
  };

  return (
    <GridColorPicker>
      {(colors || colorsArray()).map((color: ColorPickerOption) => {
        const selected = value === color.code;
        return (
          <div key={color.code}>
            <ColorOption
              htmlFor={color.code}
              color={color.code}
              selected={selected}
            >
              <FormCheckboxComponent
                dummy={true}
                bgColor={"transparent"}
                registration={{
                  name: "",
                  value: selected,
                  onChange: () => {},
                }}
              />
            </ColorOption>
            <input
              style={{ outline: `2px solid ${color.code}`, display: "none" }}
              type={"radio"}
              value={value as string}
              name={name}
              id={color.code}
              onChange={(e) => onChange(e, color.code)}
            />
          </div>
        );
      })}
    </GridColorPicker>
  );
}

export default GridColorPickerComponent;
