import styled, { css } from "styled-components";
import theme from "../../constants/theme/theme";

export const SIDE_MENU_WIDTH = "15rem";

export const ViewToggleListButtons = styled.div`
  flex-direction: column;
  padding-block: 0.5rem;
  display: none;
  border-top: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;

  @media (max-width: 570px) {
    display: flex;
  }
`;

export const SideMenuOption = styled.button<{ selected: boolean }>`
  text-align: start;
  border: none;
  padding: 0.25rem 0.5rem;
  background-color: inherit;
  transition: background-color 150ms;
  ${({ selected, theme }) =>
    selected &&
    css`
      background-color: blueviolet;
      color: white;
    `}
`;
export const SideMenu = styled.div`
  background-color: ${theme.colors.sideBar};
  transition: width 300ms ease-in-out;
  border-right: 1px solid ${theme.colors.gridBorders};
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const SideMenuWrapper = styled.div<{ isOpen: boolean }>`
  background-color: transparent;
  left: 0;
  height: 100%;
  z-index: 100;
  transition: background-color 150ms;

  ${SideMenu} {
    width: ${({ isOpen }) => (isOpen ? SIDE_MENU_WIDTH : "0rem")};
  }

  // small screens styles override
  @media (max-width: 1000px) {
    position: absolute;
    width: 100%;
    ${({ isOpen }) => css`
      background-color: ${isOpen && "rgba(0, 0, 0, 0.2)"};
      pointer-events: ${isOpen ? "unset" : "none"};
    `}
    ${SideMenu} {
      box-shadow: 3px 4px 16px 7px rgb(0 0 0 / 18%);
      border: none;
    }
  }


}
`;
