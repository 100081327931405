import { useTasksContext } from "../../context/tasks.context";
import { useCalendarsContext } from "../../context/calendars.context";
import { DATE } from "../utils/date.module";
import { TaskClass } from "../../models/classes/task.class";

export interface CalendarDayTaskMap {
  regularTasks: TaskToShow[];
  allDayTasks: TaskClass[];
  columnsCount: number;
  date: Date;
}

interface TaskToShow {
  calendarId: string;
  task: TaskClass;
  columnIndex: number;
  styleStartDate: Date;
  styleEndDate: Date;
}

/**
 * Calculates and updates the positioning information for a given list of regular tasks (not all day tasks)
 *
 * @param tasks - array of tasks to show to be updated with column positioning information
 * @param columnNum - the number of columns allowed
 */
function updatePositionInfo(tasks: TaskToShow[], columnNum: number): number {
  const columns: TaskToShow[][] = Array.from(Array(columnNum + 1), () => []);
  tasks.forEach((taskToShow) => {
    for (let colIndex = 0; colIndex < columns.length; colIndex++) {
      const column = columns[colIndex];
      const lastTaskInColumn: TaskToShow | undefined = column.at(-1);
      if (
        colIndex === columns.length - 1 ||
        lastTaskInColumn === undefined ||
        !tasksOverlap(taskToShow.task, lastTaskInColumn.task)
      ) {
        taskToShow.columnIndex = colIndex;
        column.push(taskToShow);
        break;
      }
    }
  });

  return columns.filter((column) => column.length > 0).length;
}

function tasksOverlap(task1: TaskClass, task2: TaskClass): boolean {
  return task1.startDate < task2.endDate && task2.startDate < task1.endDate;
}

function useDateTasks(columnNum: number = 3) {
  const tasks = useTasksContext();
  const { calendars } = useCalendarsContext();

  /**
   * Given a date, it returns an object with a list of the regular tasks, all day tasks, and the amount of columns
   * needed to place the tasks in. Regular tasks have a column index to indicate which column the task needs to go
   * in.
   * @param date
   */
  function getDateTasks(date: Date): CalendarDayTaskMap {
    // regular tasks refers to tasks that aren't 'all day'
    const regularTasks: TaskToShow[] = [];
    const allDayTasks: TaskClass[] = [];
    Object.keys(tasks).forEach((calendarId) => {
      if (!calendars[calendarId].show) return;

      const tasksDuringDate = DATE.getTasksHappeningDuring(
        date,
        tasks[calendarId]
      );

      const dateEnd = DATE.getNextDayMidnight(date);

      tasksDuringDate.forEach((task) => {
        if (task.allDay) {
          allDayTasks.push(task);
        } else {
          regularTasks.push({
            // adjust the styleStart and styleEnd dates so that tasks don't overflow
            styleStartDate: task.startDate < date ? date : task.startDate,
            styleEndDate: task.endDate >= dateEnd ? dateEnd : task.endDate,
            calendarId,
            task,
            columnIndex: 0,
          });
        }
      });
    });

    regularTasks.sort(
      (a, b) => a.task.startDate.valueOf() - b.task.startDate.valueOf()
    );

    allDayTasks.sort((a, b) => a.startDate.valueOf() - b.startDate.valueOf());

    const columnsCount = updatePositionInfo(regularTasks, columnNum);

    return {
      regularTasks,
      allDayTasks,
      columnsCount,
      date,
    };
  }

  return { getDateTasks };
}

export default useDateTasks;
