import styled from "styled-components";
import { BORDER_COLOR, FOCUS_COLOR } from "../form-input/form-input.styles";

export const StyledSelectInput = styled.div<{ direction: "col" | "row" }>`
  display: flex;
  flex-direction: ${({ direction }) =>
    direction === "row" ? "row" : "column"};
  gap: 0.25rem;

  select {
    border: 1px solid ${BORDER_COLOR};
    padding: 0.5rem;
    max-height: 33px;
    border-radius: 0.25rem;
    outline-offset: -2px;

    :focus-visible {
      outline: 2px solid ${FOCUS_COLOR};
    }
  }

  label {
    font-size: 0.7rem;
    color: grey;
  }
`;
