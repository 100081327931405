import { db } from "../../firebase";
import {
  collection,
  getDocs,
  query,
  where,
  documentId,
} from "firebase/firestore";
import { UserProfile } from "../../../../models/user.model";

export const getUsersDB = async (userIds: string[]) => {
  const users: UserProfile[] = [];
  for (let i = 0; i < userIds.length; i += 10) {
    const q = query(
      collection(db, "users"),
      where(documentId(), "in", userIds.slice(i, i + 10))
    );
    const usersSnapShot = await getDocs(q);
    usersSnapShot.forEach((doc) => {
      users.push(doc.data() as UserProfile);
    });
  }

  return users;
};
