import {
  addDoc,
  collection,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import {
  Calendar,
  CalendarDB,
  CalendarMap,
  RoleType,
} from "../../../../models/calendar";
import { db } from "../../firebase";

export const createCalendarDB = async (uid: string, calendar: CalendarDB) => {
  if (!uid || !calendar.name || !calendar.color)
    throw Error("Missing required field");

  await addDoc(collection(db, "calendars"), calendar);
};

export const subscribeCalendars = (
  uid: string,
  callback: (role: RoleType, calendars: CalendarMap) => void
) => {
  const calendarsCollection = collection(db, "calendars");
  const viewersQuery = query(
    calendarsCollection,
    where("viewers", "array-contains", uid)
  );

  const editorsQuery = query(
    calendarsCollection,
    where("editors", "array-contains", uid)
  );

  const ownerQuery = query(calendarsCollection, where("ownerId", "==", uid));

  const { Viewer, Editor, Owner } = RoleType;
  const unsubscribeFunctions = [
    {
      query: viewersQuery,
      role: Viewer,
    },
    {
      query: editorsQuery,
      role: Editor,
    },
    {
      query: ownerQuery,
      role: Owner,
    },
  ].map(({ query, role }) =>
    onSnapshot(query, (querySnapshot) => {
      const newCalendars: CalendarMap = {};
      querySnapshot.forEach((doc) => {
        newCalendars[doc.id] = doc.data() as Calendar;
        newCalendars[doc.id].role = role;
        newCalendars[doc.id].id = doc.id;
        newCalendars[doc.id].show = true;
      });
      callback(role, newCalendars);
    })
  );

  return () => {
    unsubscribeFunctions.forEach((unsubscribe) => unsubscribe());
  };
};

export const updateCalendarPermissions = async (
  email: string,
  role: RoleType,
  calendarId: string
) => {
  try {
    const URL = process.env.REACT_APP_FB_SHARE_CALENDAR_URL!;
    const response = await fetch(URL, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        accept: "*/*",
        mode: "no-cors",
      },
      body: JSON.stringify({
        email,
        role,
        calendarId,
      }),
    });
    if (!response.ok) throw Error("Something went wrong");
  } catch (e: any) {
    throw e;
  }
};
