import React, { useEffect, useState } from "react";
import { useTasksContext } from "../../../context/tasks.context";
import { DATE } from "../../../logic/utils/date.module";
import { useDateContext, useDateDispatch } from "../../../context/date.context";
import {
  ChartContainer,
  ChartOptions,
  DateContainer,
  DateInput,
  DateSelector,
  FlexContainer,
  PieChart,
  SubmitButton,
  TasksByDateSection,
  TasksPageBodyContainer,
  TasksPageHeader,
} from "./task-view.styles";
import { DateActions } from "../../../models/date.model";
import TaskComponent from "../shared/task/task.component";
import { useCalendarsContext } from "../../../context/calendars.context";
import { TaskClass } from "../../../models/classes/task.class";

function TaskViewComponent() {
  const tasks = useTasksContext();
  const { dates } = useDateContext();
  const { calendars } = useCalendarsContext();
  const dispatchDate = useDateDispatch();

  useEffect(() => {
    setDateFields({
      startDate: dates.at(0),
      endDate: dates.at(-1),
    });
  }, [dates]);

  const [dateFields, setDateFields] = useState<{
    startDate: Date | undefined;
    endDate: Date | undefined;
  }>({
    startDate: dates.at(0),
    endDate: dates.at(-1),
  });

  const allTaskGroups: TaskClass[][] = Object.values(tasks);
  const allTasks = new Array<TaskClass>().concat(...allTaskGroups);

  const tasksByDate = allTasks.reduce<Record<string, TaskClass[]>>(
    (object, task) => {
      const key: string = task.startDate.toLocaleDateString();
      if (!object[key]) {
        object[key] = [];
      }
      object[key].push(task);
      return object;
    },
    {}
  );

  return (
    <FlexContainer>
      <TasksPageHeader>
        <ChartContainer>
          <ChartOptions>
            <p>Options Section</p>
          </ChartOptions>
          <PieChart>
            <p>Pie Chart</p>
          </PieChart>
        </ChartContainer>
        <DateSelector>
          <DateContainer>
            <DateInput
              type={"date"}
              required={true}
              value={DATE.toHtmlInput(dateFields.startDate)}
              onChange={({ target }) =>
                // disable resetting the value to null
                setDateFields({
                  ...dateFields,
                  startDate: target.valueAsDate || dateFields.startDate,
                })
              }
            />
            <DateInput
              type={"date"}
              required={true}
              value={DATE.toHtmlInput(dateFields.endDate)}
              onChange={({ target }) =>
                // disable resetting the value to null
                setDateFields({
                  ...dateFields,
                  endDate: target.valueAsDate || dateFields.endDate,
                })
              }
            />
          </DateContainer>
          <SubmitButton
            onClick={() => {
              const { startDate, endDate } = dateFields;
              if (startDate && endDate) {
                dispatchDate({
                  type: DateActions.UpdateTasksRange,
                  payload: { startDate, endDate },
                });
              }
            }}
          >
            Submit
          </SubmitButton>
        </DateSelector>
      </TasksPageHeader>
      <TasksPageBodyContainer>
        {Object.keys(tasksByDate).map((date) => (
          <TasksByDateSection key={date}>
            <h2>{date}</h2>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              {tasksByDate[date].map((task) => (
                <TaskComponent
                  showCheckBox={true}
                  task={task}
                  calColor={calendars[task.calendarId].color}
                  key={task.id}
                  size={"lg"}
                />
              ))}
            </div>
          </TasksByDateSection>
        ))}
      </TasksPageBodyContainer>
    </FlexContainer>
  );
}

export default TaskViewComponent;
