import React, { JSX } from "react";
import { TimeBar, TimeBarStamp } from "./time-bar.styles";
import { DATE } from "../../../../logic/utils/date.module";

function TimeBarComponent(): JSX.Element {
  return (
    <TimeBar>
      {DATE.TIME_SLOTS.map((timestamp, i) => (
        <TimeBarStamp key={`${i}${timestamp}`}>{timestamp}</TimeBarStamp>
      ))}
    </TimeBar>
  );
}

export default TimeBarComponent;
