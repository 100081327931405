import React from "react";
import {
  Container,
  Feature,
  FeaturesContent,
  FeaturesSection,
} from "../landing.page.style";
// @ts-ignore
import { ReactComponent as CalendarSVG } from "../img/calendar.svg";
// @ts-ignore
import { ReactComponent as ProductivitySVG } from "../img/productivity.svg";

const AppFeature: React.FC<{ title: string; description: string }> = ({
  title,
  description,
}) => {
  return (
    <Feature>
      <h3>{title}</h3>
      <p>{description}</p>
    </Feature>
  );
};

interface FeaturesProps {
  features: boolean;
}

const FeaturesComponent: React.FC<FeaturesProps> = ({ features = true }) => {
  if (features) {
    return (
      <FeaturesSection id={"features"}>
        <FeaturesContent>
          <CalendarSVG
            height={"25rem"}
            style={{ marginLeft: "1rem", marginRight: "1rem" }}
          />
          <Container style={{ position: "relative" }}>
            <h2>Key Features</h2>
            <p>Discover what Pinboard Planner has to offer</p>
            <div>
              {/* Feature 1 */}
              <AppFeature
                title={"Intuitive Calendar"}
                description={
                  "Effortlessly manage your schedule with our intuitive and user-friendly calendar interface."
                }
              />
              {/* Feature 2 */}
              <AppFeature
                title={"Task Management"}
                description={
                  "Stay organized and keep track of your tasks and deadlines with our built-in task management system."
                }
              />
              {/* Feature 3 */}
              <AppFeature
                title={"Calendar Feature 3"}
                description={"Calendar Feature 3 description"}
              />
            </div>
          </Container>
        </FeaturesContent>
      </FeaturesSection>
    );
  } else {
    return (
      <FeaturesSection>
        <FeaturesContent>
          <Container style={{ position: "relative" }}>
            <h2>Boost Your Productivity</h2>
            <p>Experience the power of Pinboard Planner</p>
            <div>
              {/* Feature 1 */}
              <AppFeature
                title={"Goal Tracking"}
                description={
                  "Set and track your goals to ensure you stay on the path to success."
                }
              />
              {/* Feature 2 */}
              <AppFeature
                title={"Collaboration"}
                description={
                  "Work together with your team by sharing calendars and tasks, fostering collaboration and efficiency."
                }
              />
              {/* Feature 3 */}
              <AppFeature
                title={"Productivity Feature 3"}
                description={"Productivity Feature 3 description"}
              />
            </div>
          </Container>
          <ProductivitySVG height={"25rem"} />
        </FeaturesContent>
      </FeaturesSection>
    );
  }
};

export default FeaturesComponent;
