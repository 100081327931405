import { useEffect, useState } from "react";

const useCurrentDateTime = (interval = 1000 * 60) => {
  const [currentDateTime, setCurrentDateTime] = useState(new Date());

  useEffect(() => {
    // @ts-ignore
    const delay = interval - (new Date() % interval);
    setTimeout(() => {
      setCurrentDateTime(new Date());
      const intervalId = setInterval(() => {
        setCurrentDateTime(new Date());
      }, interval);

      return () => clearInterval(intervalId);
    }, delay);
    // eslint-disable-next-line
  }, []);

  return [currentDateTime];
};

export default useCurrentDateTime;
