import React from "react";
import { Calendar, RoleType } from "../../../../models/calendar";
import { usePresentationContext } from "../../../../context/presentation/presentation.context";
import { useCalendarsContext } from "../../../../context/calendars.context";
import { ModalName } from "../../../../models/modal.model";
import {
  CalendarItemColor,
  CalendarsGroupItem,
} from "./calendar-group-item.styles";
import { Flex } from "../../../modal/modal-shared.styles";
import FormCheckboxComponent from "../../../form/form-checkbox/form-checkbox.component";
import { Delete, MoreVert, Share } from "@mui/icons-material";
import { IconButton } from "../../../../global-styles/icon-button.styles";

function CalendarGroupItemComponent({
  calendar: { color, id, name, role, show },
}: {
  calendar: Calendar;
}) {
  const { toggleModalVisibility, displayContextMenu } =
    usePresentationContext();
  const { updateCalendarVisibility } = useCalendarsContext();

  const handleCalendarDeletion = () => {
    toggleModalVisibility({
      show: true,
      type: ModalName.CONFIRM,
      data: {
        confirm: async () => {
          console.log("yes");
        },
        title: "Attention!",
        description: `Are you sure you want to delete '${name}' calendar?`,
        buttons: [
          { text: "No", role: "cancel", bgColor: "lightblue" },
          { text: "Yes", role: "confirm", bgColor: "darkblue" },
        ],
      },
    });
  };

  const handleCalendarShare = () => {
    toggleModalVisibility({
      type: ModalName.SHARE_CALENDAR,
      show: true,
      data: { calendarId: id },
    });
  };

  const showCalContextMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    displayContextMenu({
      event,
      menu: [
        {
          text: "Share",
          icon: <Share fontSize={"small"} />,
          onClick: handleCalendarShare,
        },
        {
          text: "Delete",
          hoverColor: "#ff5454",
          icon: <Delete fontSize={"small"} />,
          onClick: handleCalendarDeletion,
        },
      ],
    });
  };

  return (
    <CalendarsGroupItem>
      <CalendarItemColor bgColor={color}>
        <FormCheckboxComponent
          bgColor={color}
          registration={{
            name: "",
            value: show,
            onChange: () =>
              updateCalendarVisibility(!show, role as RoleType, id),
          }}
        />
      </CalendarItemColor>
      <span>{name}</span>
      <Flex ml={"auto"} alignItems={"center"}>
        <span>
          {
            {
              [RoleType.Owner]: "🏠",
              [RoleType.Editor]: "🖊️",
              [RoleType.Viewer]: "👁️",
            }[role as RoleType]
          }
        </span>

        <IconButton variant={"round"} onClick={showCalContextMenu}>
          <MoreVert fontSize={"small"} />
        </IconButton>
      </Flex>
    </CalendarsGroupItem>
  );
}

export default CalendarGroupItemComponent;
