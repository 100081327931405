import React from "react";
import PageHeaderComponent from "./sections/page-header.component";
import PageFooterComponent from "./sections/page-footer.component";
import PageHeroComponent from "./sections/page-hero.component";
import FeaturesComponent from "./sections/features.component";
import { BodyContainer, LandingPageContent } from "./landing.page.style";

function LandingPage() {
  return (
    <LandingPageContent>
      <PageHeaderComponent />
      <BodyContainer>
        <PageHeroComponent />
        <FeaturesComponent features={true} />
        <FeaturesComponent features={false} />
        <PageFooterComponent />
      </BodyContainer>
    </LandingPageContent>
  );
}

export default LandingPage;
