import React from "react";
import { useAuth } from "../../../context/auth.context";
import useForm from "../../../logic/hooks/useForm";
import FormInputComponent from "../../form/form-input/form-input.component";
import GridColorPickerComponent, {
  COLOR_PICKER_PALETTE,
  theme,
} from "../../grid-color-picker/grid-color-picker.component";
import { createCalendarDB } from "../../../logic/firebase/db-service/calendars/calendars.db";
import { CalendarDB } from "../../../models/calendar";
import { Flex } from "../modal-shared.styles";
import { usePresentationContext } from "../../../context/presentation/presentation.context";
import { ValidationSchema } from "../../../logic/hooks/models/use-form.types";
import { FormButton } from "../../form/form.styles";

interface AddCalendarForm {
  name: string;
  color: string;
}

// REFACTOR: once theme is implemented, theme will need to come from theme file
const colorCode = () => {
  return theme === "dark"
    ? COLOR_PICKER_PALETTE[0].dark
    : COLOR_PICKER_PALETTE[0].light;
};

const initialValues: AddCalendarForm = {
  name: "",
  color: colorCode(),
};

const validationSchema: ValidationSchema<AddCalendarForm> = {
  name: { required: true },
  color: { required: true },
};

function AddCalendarMenuComponent() {
  const currentUser = useAuth();
  const { closeContextMenus } = usePresentationContext();
  const { register, handleSubmit } = useForm(initialValues, validationSchema);

  const onSubmit = async (calendar: AddCalendarForm) => {
    try {
      // TODO: reroute the user or make them login

      if (currentUser) {
        const newCalendar: CalendarDB = {
          color: calendar.color,
          name: calendar.name,
          ownerId: currentUser.uid,
        };
        await createCalendarDB(currentUser.uid, newCalendar);
        closeContextMenus();
        return true;
      } else {
        alert("You must sign in first");
        return false;
      }
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  return (
    <form
      onClick={(e) => e.stopPropagation()}
      onSubmit={handleSubmit(onSubmit)}
      noValidate={true}
      style={{ background: "white", padding: "2rem" }}
    >
      <Flex dir={"column"}>
        <FormInputComponent
          hideError={false}
          registration={register("name", "text")}
          placeholder={"Calendar name"}
        />
        <GridColorPickerComponent registration={register("color", "color")} />
        <FormButton style={{ marginTop: "1rem" }} type={"submit"}>
          Create
        </FormButton>
      </Flex>
    </form>
  );
}

export default AddCalendarMenuComponent;
