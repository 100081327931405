import styled from "styled-components";

export const CheckboxContainer = styled.div`
  display: flex;
`;

export const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;
// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
export const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const StyledCheckbox = styled.label<{
  checked: boolean;
  bgColor?: string;
  borderColor?: string;
  dummy?: boolean;
}>`
  display: flex;
  width: 16px;
  height: 16px;
  transition: all 150ms;
  border: 1px solid white;
  border-radius: 0.2rem;
  background-color: ${({ bgColor }) => bgColor || "darkblue"};
  pointer-events: ${({ dummy }) => dummy && "none"};
  border: ${({ borderColor, bgColor }) =>
    `1px solid ${borderColor || bgColor || "darkblue"}`};

  ${HiddenCheckbox}:focus + & {
    //  TODO: add focus state to checkbox and enable toggling it using the enter key
  }

  ${Icon} {
    visibility: ${({ checked }) => (checked ? "visible" : "hidden")};
  }
`;
