import { ShareCalendarProps } from "../components/modal/share-calendar-modal/share-calendar-modal.component";
import { ContextMenuPayload } from "./context-menu.model";
import { ConfirmModalPayload } from "../components/modal/confirm-modal/confirm-modal.component";
import { TaskModalPrePopulate } from "../components/modal/task-modal/task-modal.component";

export type PresentationContextValue = {
  toggleModalVisibility: (payload: ModalAction) => void;
  displayContextMenu: (contextMenu: ContextMenuPayload) => void;
  closeModals: () => void;
  closeContextMenus: () => void;
};

interface BaseModalAction {
  show: boolean;
  type: ModalName;
}

interface EditCalendarAction extends BaseModalAction {
  type: ModalName.SHARE_CALENDAR;
  data?: ShareCalendarProps;
}

interface AddTaskAction extends BaseModalAction {
  type: ModalName.TASK;
  data?: TaskModalPrePopulate;
}

interface ConfirmModelAction extends BaseModalAction {
  type: ModalName.CONFIRM;
  data?: ConfirmModalPayload;
}

export type ModalAction =
  | EditCalendarAction
  | AddTaskAction
  | ConfirmModelAction;

export enum ModalName {
  TASK = "TASK",
  SHARE_CALENDAR = "SHARE_CALENDAR",
  CONFIRM = "CONFIRM",
}
