import { TaskType } from "../../models/task.types";

export const TOP_BAR_HEIGHT = "4rem";
export const WEEK_HEADER_HEIGHT = "3.5rem";
export const TIME_BAR_WIDTH = "2.5rem";

const colors = {
  lavendarBlush: "#FCEFEF",
  blueJeans: "#4AABE8",
  greenBlueCrayola: "#1982C4",
  rosyBrown: "#B08782",
  roseTaupe: "#8C5E58",
};

const theme = {
  colors: {
    contentBackground: colors.lavendarBlush,
    differentMonth: "#b0b0b0",
    headers: colors.blueJeans,
    topBar: colors.greenBlueCrayola,
    topBarAccent: colors.rosyBrown,
    topBarAccent_light: "#e5beb8",
    sideBar: colors.lavendarBlush,
    currentDay: colors.roseTaupe,
    currentDayText: "#ffffff",
    firstDay: colors.rosyBrown,
    firstDayDiffMonth: "#e5beb8",
    gridBorders: "#b0b0b0",
    clockBar: "red",
    tasks: {
      reminder: "red",
      task: "orange",
      event: "skyblue",
    },
  },
  spacing: {
    //-------------TOP BAR--------------
    tbButtonWidth: "6.5rem",
    tbNestedButtonWidth: "5rem",
    tbButtonHeight: "2rem",
    tbIconButtonSize: "3rem",
    tbButtonBorderRadius: "2rem",
    timeBarPaddingTop: "1rem",
    timeBarViewMarginTop: "1.5rem",
    clockBarHeight: "3px",

    //----------CONTENT VIEW------------
    cvBorderRadius: "0.2rem",
    timeBarWidth: "3rem",
    timeSlotHeight: "4rem",

    //----------MINI CALENDAR-----------

    //------------TASK VIEW-------------
    tvButtonBorderRadius: "0.5rem",
    tvButtonWidth: "8rem",
    tvButtonHeight: "2rem",
    tvMargin: "0.5rem",
  },
  font: {
    //-------------TOP BAR--------------
    tbButtonSize: "medium",
    tbButtonLineHeight: "2rem",
    tbButtonWeight: "600",

    //----------CONTENT VIEW------------

    //----------MINI CALENDAR-----------
    mcWeight: "500",
    mcDaySize: "small",
    mcTitleSize: "medium",

    //------------TASK VIEW-------------
    tvButtonSize: "large",
  },
  misc: {
    //-------------TOP BAR--------------
    buttonBoxShadow: "0 5px 3px 0 rgba(0, 0, 0, 0.2)",

    //----------CONTENT VIEW------------

    //----------MINI CALENDAR-----------

    //------------TASK VIEW-------------
  },
};

export const getTaskColor = ({
  type,
  theme: { colors },
}: {
  type: string;
  theme: any;
}): string => {
  switch (type) {
    case TaskType.REMINDER:
      return colors.tasks.reminder;
    case TaskType.TASK:
      return colors.tasks.task;
    case TaskType.EVENT:
      return colors.tasks.event;
    default:
      return colors.tasks.reminder;
  }
};

export default theme;
