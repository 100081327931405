import React from "react";
import { Flex } from "../../modal-shared.styles";
import FormSelectComponent from "../../../form/form-select/form-select.component";
import FormInputComponent from "../../../form/form-input/form-input.component";
import { TaskRepeatProps } from "./task-repeat.types";
import { TaskForm } from "../task-modal.data";
import RepeatWeekDaysComponent from "./repeat-week-days/repeat-week-days.component";

function TaskRepeatComponent({
  register,
  formData,
}: TaskRepeatProps<TaskForm>) {
  return (
    <Flex dir={"column"} gap={"0.5rem"}>
      <FormInputComponent
        registration={register("repeat", "checkbox")}
        hideError={true}
        label={"Repeat?"}
        dir={"row"}
      />
      {formData.repeat && (
        <Flex as="fieldset" dir={"column"} gap={"0.5rem"}>
          <Flex gap={"0.5rem"}>
            <FormInputComponent
              label={"Every:"}
              dir={"row"}
              registration={register("repeatEvery", "number")}
              hideError={true}
              size={"sm"}
              min={"1"}
            />
            <FormSelectComponent
              options={[
                { text: "day" },
                { text: "week" },
                { text: "month" },
                { text: "year" },
              ]}
              registration={register("repeatType", "text")}
            />
          </Flex>
          {formData.repeatType === "week" && (
            <RepeatWeekDaysComponent formData={formData} register={register} />
          )}
          <FormInputComponent
            dir={"row"}
            registration={register("repeatEnd", "date")}
            label={"Repeat end?"}
            hideError={false}
          />
        </Flex>
      )}
    </Flex>
  );
}

export default TaskRepeatComponent;
