import React from "react";
import {
  WeekDayDate,
  WeekDayHeading,
  WeekDayName,
  WeekHeader,
} from "./week-days-header.styles";
import { DATE } from "../../../logic/utils/date.module";

interface WeekHeaderProps {
  offsetLeft?: boolean;
  dates?: Date[];
}

function WeekDaysHeaderComponent({
  offsetLeft = false,
  dates = [],
}: WeekHeaderProps) {
  return (
    <WeekHeader offsetLeft={offsetLeft}>
      {DATE.DAY_NAMES.map((day, i) => (
        <WeekDayHeading key={day}>
          <WeekDayName>{day}</WeekDayName>
          {dates[i] && (
            <WeekDayDate
              highlight={DATE.areSameDay(new Date(), dates[i])}
              key={`${day}${i}`}
            >
              {dates[i].getDate()}
            </WeekDayDate>
          )}
        </WeekDayHeading>
      ))}
    </WeekHeader>
  );
}

export default WeekDaysHeaderComponent;
