export interface CalendarDB {
  name: string;
  color: string;
  ownerId: string;
  viewers?: string[];
  editors?: string[];
}

export interface Calendar extends CalendarDB {
  id: string;
  role: RoleType | string;
  show: boolean;
}

export enum RoleType {
  Owner = "owner",
  Editor = "editor",
  Viewer = "viewer",
}

export type CalendarMap = { [calendarId: string]: Calendar };

export type CalendarRoleGroups = {
  [key in RoleType]: CalendarMap;
};
