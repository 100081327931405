import React from "react";

import { useDateContext } from "../../../context/date.context";
import {
  FlexContainer,
  FlexGrowContainer,
  MonthDay,
  MonthDayDate,
  MonthTasksList,
} from "./month-view.styles";
import { DATE } from "../../../logic/utils/date.module";
import { useTasksContext } from "../../../context/tasks.context";
import { useCalendarsContext } from "../../../context/calendars.context";
import WeekDaysHeaderComponent from "../week-days-header/week-days-header.component";
import TaskComponent from "../shared/task/task.component";
import { usePresentationContext } from "../../../context/presentation/presentation.context";
import { ModalName } from "../../../models/modal.model";

function MonthViewComponent() {
  const { dates } = useDateContext();
  const { calendars } = useCalendarsContext();
  const tasks = useTasksContext();
  const { toggleModalVisibility } = usePresentationContext();

  // TODO: this is temp
  const today = new Date();

  return (
    <FlexContainer direction={"column"}>
      <WeekDaysHeaderComponent />
      <FlexGrowContainer>
        {dates.map((date, index) => (
          <MonthDay
            key={date.toString()}
            length={dates.length}
            index={index}
            onClick={() => {
              toggleModalVisibility({
                type: ModalName.TASK,
                show: true,
                data: {
                  task: DATE.getTaskDates(date, true),
                },
              });
            }}
          >
            <MonthDayDate highlight={DATE.areSameDay(date, today)}>
              {date.getDate()}
            </MonthDayDate>
            <MonthTasksList>
              {Object.keys(tasks).map((calendarId) => {
                // if the calendar is hidden, return null
                if (!calendars[calendarId].show) return null;
                // only show tasks happening during the date
                const shownTasks = DATE.getTasksHappeningDuring(
                  date,
                  tasks[calendarId]
                );
                return shownTasks.map((task) => (
                  <TaskComponent
                    key={task.id}
                    task={task}
                    calColor={calendars[calendarId].color}
                  />
                ));
              })}
            </MonthTasksList>
          </MonthDay>
        ))}
      </FlexGrowContainer>
    </FlexContainer>
  );
}

export default MonthViewComponent;
