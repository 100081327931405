import { useEffect, useState } from "react";
import { useDateContext } from "../../context/date.context";
import { subscribeTasksOccurringDuringInterval } from "../firebase/db-service/calendars/tasks/tasks.db";
import { useCalendarsContext } from "../../context/calendars.context";
import {
  TaskAction,
  TaskActions,
  TasksByCalendarMap,
} from "../../models/task.types";
import { TaskClass } from "../../models/classes/task.class";

const useTasks = () => {
  const [tasks, setTasks] = useState<TasksByCalendarMap>({});
  const { dates } = useDateContext();
  const { calendars } = useCalendarsContext();

  useEffect(() => {
    // TODO: make sure to clear tasks from the previous views
    try {
      const startDate = dates.at(0);
      const endDate = dates.at(-1);
      if (!startDate || !endDate) return;
      return subscribeTasksOccurringDuringInterval(
        calendars,
        startDate,
        endDate,
        (calendarId: string, tasks: TaskClass[]) => {
          dispatch({
            type: TaskActions.SetAll,
            payload: { calendarId, tasks },
          }).then();
        }
      );
    } catch (e) {
      console.log(e);
    }
    // eslint-disable-next-line
  }, [dates, calendars]);

  const dispatch = async ({ type, payload }: TaskAction): Promise<any> => {
    switch (type) {
      case TaskActions.SetAll:
        return setTasks((tasks) => ({
          ...tasks,
          [payload.calendarId]: payload.tasks,
        }));
      case TaskActions.SetIsComplete:
        // const { id, isComplete } = payload;
        // TODO: fix updating a task
        // await updateTask(currentUser.uid, id, { isComplete });
        break;

      default:
        throw new Error(`${type} is not recognized!`);
    }
  };

  return { tasks, dispatch };
};

export default useTasks;
