import React, { ChangeEvent, FormEvent, useState } from "react";
import PropTypes from "prop-types";
import {
  Form,
  FormButton,
  FormButtonGroup,
  FormError,
  FormItem,
  FormTitle,
  Input,
  Label,
  Select,
} from "./form.styles";
import { Link } from "react-router-dom";

// @ts-ignore
import SpinnerIcon from "../../icons/spinner-icon/spinner.icon";
// @ts-ignore
import { DATE } from "../../logic/utils/date.module";

export interface FormField<T = string> {
  name: T;
  info:
    | { type: "select"; options: { text: string; value?: string }[] }
    | {
        type:
          | "color"
          | "datetime-local"
          | "text"
          | "email"
          | "password"
          | "textarea";
      };
  placeholder?: string;
  label?: string;
  required?: boolean; // defaults to true
  direction?: "row" | "column";
  initialValue?: string | Date | boolean;
  disabled?: boolean;
  editing?: boolean;
}

export interface BaseButton {
  text: string;
  bgColor: string;
  onClick?: () => Promise<void>;
}

export interface FormButtonProps extends BaseButton {
  type: "submit" | "button";
  resetFormOnSuccess?: boolean;
}

interface FormProps {
  // TODO: add support for delete button on the form
  buttons: FormButtonProps[];
  title: string;
  fields: FormField[];
  onSubmit: (formData: FormState) => Promise<void>;
  secondaryText?: string;
  error?: string;
  link?: { path: string; text: string };
  children?: React.ReactNode;
  resetOnSuccess?: boolean;
  resetError?: () => void;
}

export type FormState = { [key: string]: string };

const getFormDefaultValues = (fields: FormField[], reset = false) =>
  fields.reduce((accum, { name, info, initialValue }) => {
    if (initialValue !== undefined && !reset)
      return {
        ...accum,
        [name]:
          initialValue instanceof Date
            ? DATE.toHTML_Datetime(initialValue)
            : initialValue,
      };

    switch (info.type) {
      case "color":
        return { ...accum, [name]: "#ffffff" };
      case "datetime-local":
        return { ...accum, [name]: DATE.toHTML_Datetime(new Date()) };
      case "select":
        return {
          ...accum,
          [name]: info.options[0]?.value || info.options[0]?.text,
        };
      default:
        return { ...accum, [name]: "" };
    }
  }, {});

function FormComponent({
  buttons,
  title,
  onSubmit,
  error,
  link,
  fields,
  secondaryText,
  children,
  resetOnSuccess,
  resetError,
}: FormProps) {
  const [state, setState] = useState<FormState>(getFormDefaultValues(fields));

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = ({
    target,
  }: ChangeEvent<
    HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
  >) => {
    setState({ ...state, [target.name]: target.value });
    resetError && resetError();
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    setIsLoading(true);
    e.preventDefault();
    try {
      await onSubmit(state);
      resetOnSuccess && resetForm(); // reset the form
    } catch (e) {
      alert(e);
    } finally {
      setIsLoading(false);
    }
  };

  const resetForm = () => {
    setState(getFormDefaultValues(fields, true));
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Form onSubmit={handleSubmit}>
        <FormTitle>{title}</FormTitle>
        {secondaryText && <p>{secondaryText}</p>}
        {fields.map(
          ({
            name,
            info,
            placeholder = "",
            label,
            required = true,
            direction,
            disabled = false,
          }) => (
            <FormItem key={name} direction={direction}>
              {label && <Label htmlFor={name}>{label}</Label>}
              {info.type === "select" ? (
                <Select
                  disabled={disabled}
                  name={name}
                  id={name}
                  onChange={handleChange}
                  value={state[name]}
                  required
                >
                  {info.options.map(({ text, value }) => (
                    <option key={text} value={value || text}>
                      {text}
                    </option>
                  ))}
                </Select>
              ) : (
                <Input
                  disabled={disabled}
                  id={name}
                  name={name}
                  onChange={handleChange}
                  value={state[name]}
                  placeholder={placeholder}
                  required={required}
                  {...(info.type === "textarea"
                    ? { as: info.type }
                    : { type: info.type })}
                />
              )}
            </FormItem>
          )
        )}
        {children}
        {link && <Link to={link.path}>{link.text}</Link>}
        <FormError>{error}</FormError>
        <FormButtonGroup>
          {buttons.map((button) => (
            <FormButton
              key={button.text}
              type={button.type}
              onClick={async () => {
                if (button.onClick) await button.onClick();
                button.resetFormOnSuccess && resetForm();
              }}
              bgColor={button.bgColor}
            >
              {isLoading && button.type === "submit" ? (
                <SpinnerIcon />
              ) : (
                button.text
              )}
            </FormButton>
          ))}
        </FormButtonGroup>
      </Form>
    </div>
  );
}

FormComponent.propTypes = {
  buttons: PropTypes.array.isRequired,
  title: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};

export default FormComponent;
