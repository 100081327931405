import styled from "styled-components";

export const Circle = styled.div<{
  bgColor?: string;
  padding?: string;
  color?: string;
  width?: string;
  borderColor?: string;
  size?: "sm" | "md" | "lg";
}>`
  aspect-ratio: 1;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  border-radius: 50%;
  background-color: ${({ bgColor }) => bgColor};
  padding: ${({ padding }) => padding || "0.75rem"};
  color: ${({ color }) => color};
  width: ${({ width }) => width};
  border: ${({ borderColor }) => borderColor && `1px solid ${borderColor}`};
  font-size: ${({ size }) =>
    ({ sm: "0.5rem", md: "0.85rem", lg: "1rem" }[size || "lg"])};
  padding: ${({ size, padding }) =>
    padding || { sm: "0.35rem", md: "0.85rem", lg: "0.75rem" }[size || "lg"]};
`;
