import { useEffect, useState } from "react";
import { subscribeCalendars } from "../firebase/db-service/calendars/calendars.db";
import {
  CalendarMap,
  CalendarRoleGroups,
  RoleType,
} from "../../models/calendar";
import { useAuth } from "../../context/auth.context";

export interface UseCalendars {
  calendars: CalendarMap;
  updateCalendarVisibility: (
    show: boolean,
    roleType: RoleType,
    calendarId: string
  ) => void;
}

function useCalendars(): UseCalendars {
  const [calendars, setCalendars] = useState<CalendarRoleGroups>({
    [RoleType.Editor]: {},
    [RoleType.Viewer]: {},
    [RoleType.Owner]: {},
  });
  const currentUser = useAuth();

  useEffect(() => {
    try {
      if (currentUser?.uid) {
        return subscribeCalendars(currentUser?.uid, (role, calendarMap) => {
          setCalendars((calendars) => ({ ...calendars, [role]: calendarMap }));
        });
      } else {
        throw Error("User not defined");
      }
    } catch (e) {
      console.log(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateCalendarVisibility = (
    show: boolean,
    roleType: RoleType,
    calendarId: string
  ) => {
    const clone = structuredClone(calendars);
    clone[roleType][calendarId].show = show;
    setCalendars(clone);
  };

  return {
    calendars: Object.entries(calendars).reduce<CalendarMap>(
      (accum, [role, calendarMap]) => ({ ...accum, ...calendarMap }),
      {}
    ),
    updateCalendarVisibility,
  };
}

export default useCalendars;
