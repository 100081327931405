import styled from "styled-components";
import { FlexGrowContainer } from "../month-view/month-view.styles";
import theme, { TIME_BAR_WIDTH } from "../../../constants/theme/theme";

export const WeekDay = styled.div`
  height: 100%;
  width: calc(100% / 7);
  position: relative;
  overflow: visible;
`;

export const WeekDays = styled.div`
  display: flex;
  flex: 1;
  background: ${theme.colors.contentBackground};
  border-top: 1px solid ${theme.colors.gridBorders};
  border-bottom: 1px solid ${theme.colors.gridBorders};
  overflow: visible;
`;

export const GridSlot = styled.div`
  border-right: 1px solid ${theme.colors.gridBorders};
  border-bottom: 1px solid ${theme.colors.gridBorders};
  height: ${theme.spacing.timeSlotHeight};
  width: 100%;
`;

export const FlexGrowScrollContainer = styled(FlexGrowContainer)`
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 0;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #000000;
    width: 2rem;
  }
`;

export const AllDayWeekTasksBar = styled.div`
  width: 100%;
  margin-left: calc(${TIME_BAR_WIDTH} - 1px);
  border-left: 1px solid ${theme.colors.gridBorders};
  padding-block: 1.5rem;
`;
