import React, { createContext, useContext } from "react";
import useTasks from "../logic/hooks/useTasks";
import { TaskAction, TasksByCalendarMap } from "../models/task.types";

const TaskContext = createContext<TasksByCalendarMap>({});
const TaskDispatchContext = createContext<any>(null);
export const useTasksContext = () => useContext(TaskContext);

export const useTasksDispatch = (): (({
  type,
  payload,
}: TaskAction) => Promise<any>) => useContext(TaskDispatchContext);

const TasksContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { tasks, dispatch } = useTasks();

  return (
    <TaskContext.Provider value={tasks}>
      <TaskDispatchContext.Provider value={dispatch}>
        {children}
      </TaskDispatchContext.Provider>
    </TaskContext.Provider>
  );
};

export default TasksContextProvider;
