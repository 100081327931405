import React from "react";
import { Circle } from "../../../../../styles.global";
import { Flex } from "../../../modal-shared.styles";
import { TaskForm } from "../../task-modal.data";
import { RegisterFunc } from "../../../../../logic/hooks/models/use-form.types";
import { DATE } from "../../../../../logic/utils/date.module";

interface RepeatWeekDaysProps {
  formData: TaskForm;
  register: RegisterFunc<TaskForm>;
}

function RepeatWeekDaysComponent({ formData, register }: RepeatWeekDaysProps) {
  return (
    <Flex gap={"0.5rem"}>
      {DATE.REPEAT_WEEK_DAYS.map((weekDay) => {
        const { name, onChange, value } = register(weekDay, "checkbox");
        return (
          <Circle
            size={"sm"}
            key={weekDay}
            bgColor={formData[weekDay] ? "darkblue" : "white"}
            borderColor={"darkblue"}
            color={formData[weekDay] ? "white" : "black"}
            as={"label"}
            htmlFor={weekDay}
            style={{ cursor: "pointer", flex: "1" }}
          >
            <input
              style={{ display: "none" }}
              type={"checkbox"}
              id={weekDay}
              name={name}
              onChange={onChange}
              checked={value as boolean}
            />
            {weekDay}
          </Circle>
        );
      })}
    </Flex>
  );
}

export default RepeatWeekDaysComponent;
