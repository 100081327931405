import { DATE } from "../../../logic/utils/date.module";
import { RepeatWeekDays, Task, TaskType } from "../../../models/task.types";
import { ValidationSchema } from "../../../logic/hooks/models/use-form.types";
import { RepeatType } from "./task-repeat/task-repeat.types";
import { TaskClass } from "../../../models/classes/task.class";

export const COLOR_PICKER_COLORS = [
  { name: "Red", code: "#F00" },
  { name: "Blue", code: "#00F" },
  { name: "Green", code: "#0F0" },
  { name: "Yellow", code: "#FF0" },
  { name: "Purple", code: "#800080" },
  { name: "Orange", code: "#FFA500" },
  { name: "Pink", code: "#FFC0CB" },
  { name: "Brown", code: "#A52A2A" },
  { name: "Teal", code: "#008080" },
  { name: "Lime", code: "#00FF00" },
] as const;

const startDate = new Date();
startDate.setSeconds(0);

const endDate = DATE.timeFromDate(1);
endDate.setSeconds(0);

export interface TaskForm extends Omit<Task, "repeatInfo">, RepeatWeekDays {
  repeatEnd: Date | "";
  repeatType: RepeatType;
  repeatEvery: number;
  repeat: boolean;
}

export const blankTask: TaskForm = {
  calendarId: "",
  id: "",
  color: COLOR_PICKER_COLORS[0].code,
  type: TaskType.EVENT,
  title: "",
  description: "",
  meetingLink: "",
  isComplete: false,
  startDate,
  endDate,
  allDay: false,
  repeatEnd: "",
  repeatType: "week",
  repeatEvery: 1,
  repeat: false,
  su: false,
  mo: false,
  tu: false,
  we: false,
  th: false,
  fr: false,
  sa: false,
} as const;

export const taskValidationSchema: ValidationSchema<TaskForm> = {
  calendarId: { required: true },
  id: { required: false },
  color: { required: true },
  type: { required: true },
  title: { required: true },
  description: { required: false },
  meetingLink: { required: false },
  isComplete: { required: false },
  startDate: { required: true },
  endDate: { required: true, greaterThan: "startDate" },
  allDay: { required: false },
  repeat: { required: false },
  repeatType: { required: false },
  repeatEvery: { required: false },
  repeatEnd: { required: false, greaterThan: "startDate" },
  su: { required: false },
  mo: { required: false },
  tu: { required: false },
  we: { required: false },
  th: { required: false },
  fr: { required: false },
  sa: { required: false },
} as const;

export const mergeTaskFormWithTask = (
  blankTask: TaskForm,
  prePopulateTask: Partial<TaskClass> | undefined
): TaskForm => {
  if (!prePopulateTask) return blankTask;
  const { repeatInfo, ...restOfTask } = prePopulateTask;
  const formData: TaskForm = { ...blankTask, ...restOfTask };
  formData.repeat = Boolean(repeatInfo);
  // if the task repeats
  if (repeatInfo) {
    if (repeatInfo.repeatEndDate) {
      // if the date is set to the max possible date, that means the repeat end doesn't exit
      // (meaning the task is infinitely repeating in a way) makes it possible to query tasks without an end
      formData.repeatEnd = DATE.isMaxPossibleDate(repeatInfo.repeatEndDate)
        ? ""
        : repeatInfo.repeatEndDate;
    } else {
      formData.repeatEnd = repeatInfo.repeatEndDate;
    }

    formData.repeatEvery = repeatInfo.repeatEvery;
    formData.repeatType = repeatInfo.repeatType.type;
    // if the repeat type is week, we get the repeat day bubbles values
    if ("weekDays" in repeatInfo.repeatType) {
      DATE.REPEAT_WEEK_DAYS.forEach((weekDay) => {
        // @ts-ignore - we are checking before the loop to make sure weekDay exists
        formData[weekDay] = Boolean(repeatInfo.repeatType.weekDays[weekDay]);
      });
    }
  }

  return formData;
};
