import styled from "styled-components";

// ---------------- LANDING PAGE ----------------
export const LandingPageContent = styled.div`
  overflow-y: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BodyContainer = styled.div`
  overflow-y: auto;
  width: 100%;
`;

// ---------------- NAV BAR ----------------
export const Header = styled.header`
  background-color: #333;
  color: #fff;
  padding: 35px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
`;

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Logo = styled.img`
  max-height: 200px; /* Adjust the size as needed */
`;

export const Nav = styled.nav`
  list-style: none;
  padding: 0;
  display: flex;
  gap: 20px;
`;

export const NavBarLink = styled.a`
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
`;

export const LoginButton = styled.button`
  background-color: transparent;
  color: #fff;
  padding: 10px 20px;
  border: 2px solid #fff;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  & > a {
    color: #fff;
    text-decoration: none;
  }

  &:hover {
    background-color: #fff;
    color: #333;

    & > a {
      color: #333;
    }
  }
`;

// ---------------- HERO SECTION ----------------
export const HeroSection = styled.section`
  padding: 100px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const HeroContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  gap: 3rem;
  padding: 0 100px;
`;

export const HeroDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
`;

export const CtaButton = styled.button`
  background-color: #007bff;
  color: #fff;
  padding: 15px 30px;
  font-size: 18px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;

  & > a {
    color: #fff;
    text-decoration: none;
  }

  &:hover {
    background-color: #0056b3;
  }
`;

// ---------------- FEATURES SECTION ----------------
export const FeaturesSection = styled.section`
  padding: 100px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const FeaturesContent = styled.div`
  display: flex;
  max-width: 1200px;
  padding: 0 100px;

  h2 {
    font-size: 42px;
  }
`;

export const Feature = styled.div`
  margin: 20px;

  h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }

  p {
    font-size: 16px;
  }
`;

// ---------------- FOOTER SECTION ----------------
export const Footer = styled.footer`
  background-color: #333;
  color: #fff;
  padding: 20px 0;
  text-align: center;
  width: 100%;
`;
