import React from "react";
import { DATE } from "../../../../logic/utils/date.module";
import {
  DynamicTask,
  DynamicTaskInnerWrapper,
  TaskContent,
  TaskTitle,
} from "./dynamic-task.styles";
import { usePresentationContext } from "../../../../context/presentation/presentation.context";
import { ModalName } from "../../../../models/modal.model";
import { TaskClass } from "../../../../models/classes/task.class";

function DynamicTaskComponent({
  task,
  calColor,
  columnIndex,
  columnsCount,
  styleStartDate,
  styleEndDate,
}: {
  task: TaskClass;
  calColor: string;
  columnsCount: number;
  columnIndex: number;
  styleStartDate: Date;
  styleEndDate: Date;
}) {
  const { toggleModalVisibility } = usePresentationContext();

  return (
    <DynamicTask
      isComplete={task.isComplete}
      type={task.type}
      startDate={styleStartDate}
      endDate={styleEndDate}
      calColor={calColor}
      columnsCount={columnsCount}
      columnIndex={columnIndex}
      onClick={() =>
        toggleModalVisibility({
          type: ModalName.TASK,
          show: true,
          data: { task, isEditing: true },
        })
      }
    >
      <DynamicTaskInnerWrapper>
        <TaskContent>
          <TaskTitle>{task.title}</TaskTitle>
          <p>{DATE.toTimestamp(task.startDate)}</p>
        </TaskContent>
        {/* TODO: ADD edit icon back here */}
      </DynamicTaskInnerWrapper>
    </DynamicTask>
  );
}

export default DynamicTaskComponent;
