import React, { createContext, useContext, useState } from "react";
import { UserProfile, UsersCache } from "../models/user.model";
import { getUsersDB } from "../logic/firebase/db-service/users/user.db";

type GetUserFunc = (userIds: string[]) => Promise<UserProfile[]>;

const UsersContext = createContext<{
  fetchUsers: GetUserFunc;
  users: UsersCache;
} | null>(null);
export const useUsersContext = (): {
  fetchUsers: GetUserFunc;
  users: UsersCache;
} => useContext(UsersContext)!;

function UsersContextProvider({ children }: { children: React.ReactNode }) {
  const [users, setUsers] = useState<UsersCache>({});

  const fetchUsers: GetUserFunc = async (userIds) => {
    const userProfiles: UserProfile[] = [];
    const unknownIds: string[] = [];
    userIds.forEach((uid) => {
      const profile: UserProfile | undefined = users[uid];
      if (profile) {
        userProfiles.push(profile);
      } else unknownIds.push(uid);
    });
    // cache the unknown ids
    if (unknownIds.length) {
      const dbUserProfiles = await getUsersDB(unknownIds);
      const dbUserMap: UsersCache = dbUserProfiles.reduce((accum, curr) => {
        return { ...accum, [curr.uid]: curr };
      }, {});
      setUsers({ ...users, ...dbUserMap });
      userProfiles.concat(dbUserProfiles);
    }

    return userProfiles;
  };
  return (
    <UsersContext.Provider value={{ fetchUsers, users }}>
      {children}
    </UsersContext.Provider>
  );
}

export default UsersContextProvider;
