import React from "react";
import { FormRegistration } from "../../../logic/hooks/models/use-form.types";
import { StyledSelectInput } from "./form-select.styles";

interface FormSelectProps {
  options: { text: string; value?: string }[];
  registration: Omit<FormRegistration, "type">;
  label?: string;
  dir?: "col" | "row";
  disabled?: boolean;
}

function FormSelectComponent({
  options,
  registration: { onChange, name, value },
  label,
  dir,
  disabled = false,
}: FormSelectProps) {
  return (
    // @ts-ignore
    <StyledSelectInput direction={dir}>
      {label && <label>{label}</label>}
      <select
        onChange={onChange}
        value={value as string}
        name={name}
        disabled={disabled}
      >
        {options.map(({ text, value }) => (
          <option key={value || text} value={value || text}>
            {text}
          </option>
        ))}
      </select>
    </StyledSelectInput>
  );
}

export default FormSelectComponent;
