import React from "react";
import { useDateContext } from "../../../context/date.context";
import { FlexGrowScrollContainer } from "../week-view/week-view.styles";
import TimeBarComponent from "../shared/time-bar/time-bar.component";
import { FlexContainer } from "../month-view/month-view.styles";
import { DATE } from "../../../logic/utils/date.module";
import { DayGrid, DayViewDate, DayViewHeader } from "./day-view.styles";
import ClockBarComponent from "../shared/clock-bar/clock-bar.component";
import DynamicTaskComponent from "../shared/dynamic-task/dynamic-task.component";
import useDateTasks from "../../../logic/hooks/useDateTasks";
import GridColumnComponent from "../shared/grid-column/grid-column.component";
import { useCalendarsContext } from "../../../context/calendars.context";
import TaskComponent from "../shared/task/task.component";
import { usePresentationContext } from "../../../context/presentation/presentation.context";
import { ModalName } from "../../../models/modal.model";

function DayViewComponent() {
  const { dates } = useDateContext();
  const { getDateTasks } = useDateTasks(5);
  const today = new Date();
  const date = dates[DATE.FIRST_DAY];
  const { regularTasks, columnsCount, allDayTasks } = getDateTasks(date);
  const { calendars } = useCalendarsContext();
  const { toggleModalVisibility } = usePresentationContext();

  return (
    <FlexContainer direction={"column"}>
      <DayViewHeader
        onClick={() => {
          toggleModalVisibility({
            type: ModalName.TASK,
            show: true,
            data: { task: { ...DATE.getTaskDates(date, true), allDay: true } },
          });
        }}
      >
        <DayViewDate highlight={DATE.areSameDay(date, today)}>
          <span>{DATE.getDayName(date).substring(0, 3)}</span>
          <span>{date.getDate()}</span>
        </DayViewDate>
        <div
          style={{
            display: "flex",
            paddingInline: "10px",
            flexWrap: "wrap",
          }}
        >
          {allDayTasks.map((task) => (
            <TaskComponent
              key={task.id}
              task={task}
              calColor={calendars[task.calendarId].color}
            />
          ))}
        </div>
      </DayViewHeader>
      <FlexGrowScrollContainer className={"scroll"}>
        <div style={{ display: "flex", width: "100%", marginTop: "1.5rem" }}>
          <TimeBarComponent />
          <DayGrid>
            {regularTasks.map((taskToShow) => (
              <DynamicTaskComponent
                key={taskToShow.task.id}
                columnsCount={columnsCount}
                calColor={calendars[taskToShow.calendarId].color}
                {...taskToShow}
              />
            ))}

            {DATE.areSameDay(date, today) && <ClockBarComponent />}
            <GridColumnComponent date={date} />
          </DayGrid>
        </div>
      </FlexGrowScrollContainer>
    </FlexContainer>
  );
}

export default DayViewComponent;
