import React, { useState } from "react";
import TopBarComponent from "../../components/topbar/topBarComponent";
import ContentComponent from "../../components/content/content.component";

function ApplicationPage() {
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(true);

  return (
    <>
      <TopBarComponent
        toggleSideMenu={() => setIsSideMenuOpen(!isSideMenuOpen)}
      />

      <ContentComponent
        isSideMenuOpen={isSideMenuOpen}
        closeSideMenu={() => {
          setIsSideMenuOpen(false);
        }}
      />
    </>
  );
}

export default ApplicationPage;
