import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthActionContext } from "../../context/auth.context";
import FormComponent, {
  FormField,
  FormState,
} from "../../components/form/form.component";
import { FormWrapper } from "../../components/form/form.styles";
import { UserFormPayload, UserPayload } from "../../models/user.model";

const fields: FormField<keyof UserFormPayload>[] = [
  {
    name: "firstName",
    info: {
      type: "text",
    },
    placeholder: "First name",
    label: "Enter your first name",
  },
  {
    name: "lastName",
    info: {
      type: "text",
    },
    placeholder: "Last name",
    label: "Enter your last name",
  },
  {
    name: "email",
    info: {
      type: "email",
    },
    placeholder: "Email address",
    label: "Enter your email",
  },
  {
    name: "password",
    info: {
      type: "password",
    },
    placeholder: "Password",
    label: "Choose a password",
  },
  {
    name: "passwordRepeat",
    info: {
      type: "password",
    },
    placeholder: "Password",
    label: "Renter the password",
  },
];

function SignupPage() {
  const { signUp } = useAuthActionContext();
  const navigate = useNavigate();
  const [error, setError] = useState<string>("");

  const handleSubmit = async ({
    email,
    password,
    passwordRepeat,
    firstName,
    lastName,
  }: FormState) => {
    try {
      if (password !== passwordRepeat) {
        throw new Error("Passwords don't match!");
      }
      const userPayload: UserPayload = {
        email,
        password,
        firstName,
        lastName,
      };
      await signUp(userPayload);
      navigate("/");
    } catch (e: any) {
      console.dir(e);
      setError(
        e?.code === "auth/email-already-in-use"
          ? "Email already in use"
          : e.message
      );
      throw e;
    }
  };

  return (
    <FormWrapper direction={"column"}>
      <FormComponent
        buttons={[{ text: "Sign up", bgColor: "darkblue", type: "submit" }]}
        title={"Create an account now"}
        link={{ text: "Already a user?", path: "/login" }}
        fields={fields}
        error={error}
        onSubmit={handleSubmit}
      />
    </FormWrapper>
  );
}

export default SignupPage;
