import styled from "styled-components";
import { DATE } from "../../../logic/utils/date.module";
import { VIEW_TYPES } from "../../../constants/types";
import theme from "../../../constants/theme/theme";
import { SIDE_MENU_WIDTH } from "../side-menu.styles";

const sameDayCheck = (date: Date) => DATE.areSameDay(new Date(), date);
const firstOfWeekOrDayCheck = (
  currentView: string,
  date: Date,
  globalDates: Date[]
) =>
  (currentView === VIEW_TYPES.WEEK || currentView === VIEW_TYPES.DAY) &&
  DATE.areSameDay(date, globalDates[DATE.FIRST_DAY]);
const sameMonthAndFirstDayCheck = (
  currentView: string,
  date: Date,
  globalDates: Date[]
) =>
  currentView === VIEW_TYPES.MONTH &&
  date.getMonth() === DATE.getValidDate(globalDates).getMonth() &&
  date.getDate() === 1;

const dayColors = (date: Date, currentView: string, globalDates: Date[]) => {
  if (sameDayCheck(date)) {
    return `background-color: ${theme.colors.currentDay}; color: white;`;
  } else if (
    firstOfWeekOrDayCheck(currentView, date, globalDates) ||
    sameMonthAndFirstDayCheck(currentView, date, globalDates)
  ) {
    return `background-color: ${theme.colors.firstDay}; color: white;`;
  }
};

const SPACING_PERCENTAGE = "3%";
const SPACING = 3;

export const MiniCalendar = styled.div`
  width: ${SIDE_MENU_WIDTH};
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
`;

export const MiniCalendarTitle = styled.div`
  height: 2.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: ${theme.font.mcTitleSize};
  font-weight: ${theme.font.mcWeight};
  background: ${({ theme }) => theme.colors.headers};
  align-items: center;
`;

export const MiniMonthYearName = styled.span<{ ml: string }>`
  margin-left: ${({ ml }) => ml};
`;

export const MiniButtonContainer = styled.div`
  margin-left: auto;
  display: flex;
  margin-right: ${SPACING_PERCENTAGE};
`;

export const MiniDayList = styled.div`
  display: flex;
  height: 1rem;
  font-weight: ${theme.font.mcWeight};
  padding-inline: 0.5rem;
  background: ${({ theme }) => theme.colors.headers};
  gap: ${SPACING_PERCENTAGE};
  width: 100%;
`;

export const MiniDay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${theme.font.mcDaySize};
  flex-basis: calc(100% / 7);
  padding-bottom: 0.25rem;
`;

export const MiniDateList = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - ${SPACING * 2}%);
  gap: ${SPACING_PERCENTAGE};
`;

export const MiniDate = styled.div`
  width: calc(82% / 7);
  aspect-ratio: 1;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${theme.font.mcDaySize};
  margin-top: ${SPACING_PERCENTAGE};
  ${({
    date,
    currentView,
    globalDates,
  }: {
    date: Date;
    currentView: string;
    globalDates: Date[];
  }) => dayColors(date, currentView, globalDates)};
  cursor: pointer;
  transition: background-color 250ms;

  :hover {
    background-color: lightgrey;
  }
`;
