import React from "react";
import { BaseButton } from "../../form/form.component";
import { Flex, Modal, ModalButton } from "../modal-shared.styles";

export interface ConfirmModalButton extends BaseButton {
  role: "cancel" | "confirm";
}

export interface ConfirmModalPayload {
  confirm: () => Promise<void>;
  title: string;
  description: string;
  buttons: ConfirmModalButton[];
}

interface ConfirmModalProps extends ConfirmModalPayload {
  close: () => void;
}

function ConfirmModalComponent({
  title,
  confirm,
  close,
  description,
  buttons,
}: ConfirmModalProps) {
  return (
    <Modal onClick={(e) => e.stopPropagation()}>
      <h2>{title}</h2>
      <p>{description}</p>
      <Flex alignSelf={"flex-end"} gap={"1rem"} mt={"1rem"}>
        {buttons.map(({ text, role }) => (
          <ModalButton
            bgColor={"darkblue"}
            key={text}
            onClick={async () => {
              if (role === "confirm") {
                await confirm();
              }
              close();
            }}
          >
            {text}
          </ModalButton>
        ))}
      </Flex>
    </Modal>
  );
}

export default ConfirmModalComponent;
